import React from "react";
import { Alert, Button, Snackbar } from "@mui/material";

const ReusableAlert = ({ title, description, variant, onClose }) => {
  return (
    <Snackbar
      open={!!title}
      autoHideDuration={3000} 
      onClose={onClose} 
      action={
        <Button color="inherit" onClick={onClose}>
          Close
        </Button>
      }
      style={{ position: "fixed", bottom: 16, right: 16 }}
    >
      <Alert onClose={onClose} severity={variant}>
        <strong>{title}</strong>: {description}
      </Alert>
    </Snackbar>
  );
};

export default ReusableAlert;
