import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography, TextField, Button } from '@mui/material';

const ProductPriceModal = ({ open, handleClose, handleSave, initialData }) => {
    const [productRate, setProductRate] = useState({});

    useEffect(() => {
        setProductRate(initialData);
    }, [initialData]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProductRate({
            ...productRate,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        handleSave(productRate);
    };

    return (
        <Modal open={open} onClose={handleClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <Typography variant="h6" component="h2" gutterBottom>
                    Update Product Prices
                </Typography>
                <form onSubmit={handleSubmit}>
                    <TextField
                        label="Zebra Blinds"
                        type="number"
                        name="Zebra_blinds"
                        value={productRate.Zebra_blinds || ''}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Roller Shades"
                        type="number"
                        name="Roller_shades"
                        value={productRate.Roller_shades || ''}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Fauxwood"
                        type="number"
                        name="Fauxwood"
                        value={productRate.Fauxwood || ''}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                    <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
                        Save Changes
                    </Button>
                </form>
            </Box>
        </Modal>
    );
};

export default ProductPriceModal;
