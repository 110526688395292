
import {
    Chart as ChartJS,
    LineElement,
    PointElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend
} from 'chart.js'
import { Line } from 'react-chartjs-2'

ChartJS.register(
    LineElement,
    PointElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend
);

export const LineChart = ({ analData }) => {

    const data = {
        labels: ['Jan', 'Feb', 'Mar', "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        datasets: [{
            label: 'Sales $',
            data: analData,
            borderColor: 'aqua',
            tension: 0.4
        }]
    }

    return (
        <div class="card">
            <div class="card-body">
                <Line
                    data={data}
                ></Line>
            </div>
        </div>

    )
}

export default LineChart