import axios from "axios"
import { API_URI } from "../../constants/GlobalConstants"
import { FETCH_ITEMS_FAILURE, FETCH_ITEMS_REQUEST, FETCH_ITEMS_SUCCESS } from "../../constants/InventoryConstants"

export const createInvItemAction = (val,token) => async (dispatch) => {
    console.log(val);
    try {
        dispatch({ type: 'INV_ITEM_CREATE_REQUEST' })
        const { data } = await axios.post(`${API_URI}/api/inventory/add-item`, val,
            {
                headers: {
                  Authorization: `Bearer ${token}`
                }
              }
        )
        dispatch({ type: 'INV_ITEM_CREATE_SUCCESS', payload: data })
    } catch (error) {
        dispatch({
            type: 'INV_ITEM_CREATE_FAIL',
            payload: error.response && error.response.data.message ?
                error.response.data.message : error.message
        })
    }
}

export const getInvItemsByCat = (val,token) => async (dispatch) => {
    try {
        dispatch({ type: 'INV_ITEMS_GET_REQUEST' })
        const { data } = await axios.get(`${API_URI}/api/inventory/get-items-by-cat/${val}`,{
            headers: {
              Authorization: `Bearer ${token}`
            }
          })
        dispatch({ type: 'INV_ITEMS_GET_SUCCESS', payload: data })
    } catch (error) {
        dispatch({
            type: 'INV_ITEMS_GET_FAIL',
            payload: error.response && error.response.data.message ?
                error.response.data.message : error.message
        })
    }
}

export const fetchItems = (token) => async (dispatch) => {
    dispatch({ type: FETCH_ITEMS_REQUEST });

    try {
        const response = await axios.get(`${API_URI}/api/inventory/get-items-by-cat`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        dispatch({
            type: FETCH_ITEMS_SUCCESS,
            payload: response.data
        });
    } catch (error) {
        dispatch({
            type: FETCH_ITEMS_FAILURE,
            payload: 'Failed to load data. Please try again later.'
        });
    }
};