import axios from "axios";
import { API_URI } from "../../constants/GlobalConstants";
import { TRANSACTION_LIST_FAIL, TRANSACTION_LIST_REQUEST, TRANSACTION_LIST_SUCCESS } from "../../constants/TransactionContants";

export const fetchTransactions = (id, token, page = 1, monthOffset = 0) => async (dispatch) => {
  try {
    dispatch({ type: TRANSACTION_LIST_REQUEST });

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const params = {
      id, 
      page,
      limit: 10,
      monthOffset,
    };

    const { data } = await axios.get(
      `${API_URI}/api/transaction/transaction-list`,
      {
        params,
        ...config,
      }
    );

    dispatch({
      type: TRANSACTION_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TRANSACTION_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};