export const NotificationBadge = ({ colour, icon, value, message1, message2 }) => {
    return (<div className={`alert border-0 bg-light-${colour} alert-dismissible fade show py-1`
    }>
        <div className="d-flex align-items-center">
            <div className="fs-3 text-success">
                <i className="bi bi-check-circle-fill" />
            </div>
            <div className="ms-3">
                <div className="text-success">{message1}&nbsp;<strong className='text-light'>{icon} {value}</strong> &nbsp;{message2}</div>
            </div>
        </div>
        <button
            type="button"
            className="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
        />
    </div>)
}