import React from "react";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const PricingForm = ({ formData, handleChange, costAndSellOptions }) => {
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12 col-lg-8 px-4">
          <div
            className="card shadow-none bg-light border"
            style={{ padding: "20px", maxWidth: "100%" }}
          >
            <div className="card-body">
              <h5 className="card-title mb-4 text-success">Pricing</h5>
              <div className="row g-3">
                {/* Selling Type Field */}
                <div className="col-12">
                  <label className="form-label">
                    Selling Type
                    <Tooltip
                      title={
                        <span style={{ fontSize: ".8rem" }}>
                          Selling calculated/evaluated per unit (e.g., Brackets
                          are calculated individually, therefore, the <span className="text-success">
                            selling unit
                          </span> will be selling/Unit)
                        </span>
                      }
                      arrow
                    >
                      <IconButton size="small" sx={{ color: "#fff" }}>
                        <HelpOutlineIcon fontSize="inherit" />
                      </IconButton>
                    </Tooltip>
                  </label>
                  <select
                    className="form-select"
                    name="sellType"
                    value={formData.sellType || ""}
                    onChange={handleChange}
                  >
                    <option value="">Select Selling Type</option>
                    {costAndSellOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Conditionally Render Selling Price Field */}
                {formData.sellType && (
                  <div className="col-12">
                    <label className="form-label">
                      Selling Price
                      <Tooltip
                        title={
                          <span style={{ fontSize: ".8rem" }}>
                            Enter the <span className="text-success">selling price</span> of the item.
                          </span>
                        }
                        arrow
                      >
                        <IconButton size="small" sx={{ color: "#fff" }}>
                          <HelpOutlineIcon fontSize="inherit" />
                        </IconButton>
                      </Tooltip>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Selling Price"
                      pattern="^\d*(\.\d{0,2})?$"
                      name="sellPrice"
                      value={formData.sellPrice || ""}
                      onChange={handleChange}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingForm;
