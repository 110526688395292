import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "90vw",
    height: "80vh",
    bgcolor: '#16223b',
    border: '2px solid #373737',
    boxShadow: 24,
    pt: 1,
    px: 1,
    pb: 3,
};


export function NoteModel(open, setOpen, updateNotes, setUpdateNotes, handleUpdateNotes) {

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{ ...style }}>
                    <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-1 mx-2 py-4">
                        <div className="breadcrumb-title pe-3">Note-board</div>
                        <div className="ps-3">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item">
                                        Dealer View
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        Dealer remark/notes
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="card radius-5">
                        <div className="card-body">
                            <div className="row g-3">
                                <div className="col-12 col-lg-12 col-xl-12 d-flex">
                                    <div className="card mb-0 radius-10 border shadow-none w-100">
                                        <div className="card-body">
                                            <div className="col-12">
                                                <label className="form-label">Dealer Notes/Remarks</label>
                                                <textarea
                                                    className="form-control"
                                                    placeholder=" Remarks, order tag..."
                                                    rows="10" style={{ height: "51vh" }}
                                                    value={updateNotes}
                                                    onChange={(e) => setUpdateNotes(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-header py-3 bg-transparent">
                            <div className="d-sm-flex align-items-center">
                                <h5 className="mb-2 mb-sm-0">Update the Notes</h5>
                                <div className="ms-auto">
                                    <button type="submit "
                                        className="btn btn-secondary mx-2"
                                        onClick={handleClose}
                                    >
                                        Cancel
                                    </button>
                                    <button type="submit"
                                        className="btn btn-primary"
                                        onClick={handleUpdateNotes}
                                    >
                                        Update
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>

            </Modal>
        </div >
    );
}