import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../layout/Loader";
import { fetchDashboardData } from "../../../redux/actions/DashboarActions";

const DashboardCharts = () => {
  const dispatch = useDispatch();
  const { loading, data, error } = useSelector((state) => state.dashboard);
  const userLogin = useSelector((state) => state.userSignIn);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (userInfo?.token) {
      dispatch(fetchDashboardData(userInfo.token));
    }
  }, [dispatch, userInfo?.token]);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!data) {
    return <div>No data available</div>;
  }

  const formatPercentage = (value) => (value >= 0 ? `+${value}%` : `${value}%`);

  return (
    <div className="row row-cols-1 row-cols-lg-2 row-cols-xl-2 row-cols-xxl-4">
      <div className="col">
        <div className="card radius-10">
          <div className="card-body">
            <div className="d-flex align-items-center">
              <div className="">
                <p className="mb-1">Total Orders in {data.currentMonthName}</p>
                <h4 className="mb-0 text-primary">{data.totalOrdersCurrentMonth}</h4>
              </div>
              <div className="ms-auto fs-2 text-primary">
                <i className="bi bi-cart-check"></i>
              </div>
            </div>
            <div className="border-top my-2"></div>
            <small className="mb-0">
              <span className={`text-${data.totalOrdersComparison >= 0 ? 'success' : 'danger'}`}>
                {formatPercentage(data.totalOrdersComparison)} <i className={`bi bi-arrow-${data.totalOrdersComparison >= 0 ? 'up' : 'down'}`}></i>
              </span>{" "}
              Compared to {data.lastMonthName}
            </small>
          </div>
        </div>
      </div>
      <div className="col">
        <div className="card radius-10">
          <div className="card-body">
            <div className="d-flex align-items-center">
              <div className="">
                <p className="mb-1">Total Sales in {data.currentMonthName}</p>
                <h4 className="mb-0 text-success">${data.totalSalesCurrentMonth}</h4>
              </div>
              <div className="ms-auto fs-2 text-success">
                <i className="bi bi-recycle"></i>
              </div>
            </div>
            <div className="border-top my-2"></div>
            <small className="mb-0">
              <span className={`text-${data.totalSalesComparison >= 0 ? 'success' : 'danger'}`}>
                {formatPercentage(data.totalSalesComparison)} <i className={`bi bi-arrow-${data.totalSalesComparison >= 0 ? 'up' : 'down'}`}></i>
              </span>{" "}
              Compared to {data.lastMonthName}
            </small>
          </div>
        </div>
      </div>
      <div className="col">
        <div className="card radius-10">
          <div className="card-body">
            <div className="d-flex align-items-center">
              <div className="">
                <p className="mb-1">Overall Balance in {data.currentMonthName}</p>
                <h4 className="mb-0 text-pink">${data.overallBalanceCurrentMonth}</h4>
              </div>
              <div className="ms-auto fs-2 text-pink">
                <i className="bi bi-bag-check"></i>
              </div>
            </div>
            <div className="border-top my-2"></div>
            <small className="mb-0">
              <span className={`text-${data.overallBalanceComparison >= 0 ? 'success' : 'danger'}`}>
                {formatPercentage(data.overallBalanceComparison)} <i className={`bi bi-arrow-${data.overallBalanceComparison >= 0 ? 'up' : 'down'}`}></i>
              </span>{" "}
              Compared to {data.lastMonthName}
            </small>
          </div>
        </div>
      </div>
      <div className="col">
        <div className="card radius-10">
          <div className="card-body">
            <div className="d-flex align-items-center">
              <div className="">
                <p className="mb-1">Total Amount Received in {data.currentMonthName}</p>
                <h4 className="mb-0 text-orange">${data.totalAmountReceivedCurrentMonth}</h4>
              </div>
              <div className="ms-auto fs-2 text-orange">
                <i className="bi bi-piggy-bank"></i>
              </div>
            </div>
            <div className="border-top my-2"></div>
            <small className="mb-0">
              <span className={`text-${data.totalAmountReceivedComparison >= 0 ? 'success' : 'danger'}`}>
                {formatPercentage(data.totalAmountReceivedComparison)} <i className={`bi bi-arrow-${data.totalAmountReceivedComparison >= 0 ? 'up' : 'down'}`}></i>
              </span>{" "}
              Compared to {data.lastMonthName}
            </small>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardCharts;
