import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Header from "../../components/layout/Header";
import Loader from "../../components/layout/Loader";
import Sidebar from "../../components/layout/Sidebar";
import OrderList from "../../components/order/OrderList";
import { listOrderAction } from "../../redux/actions/OrderAction";
import { Box, TablePagination, Tabs, Tab, Typography } from "@mui/material";

const ListOrder = ({ nav }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [filter, setFilter] = useState("All");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("dealerName");
  const [tabValue, setTabValue] = useState(0);
  const [refresh, setRefresh] = useState(false);

  const userLogin = useSelector((state) => state.userSignIn);
  const { userInfo } = userLogin;
  const orderList = useSelector((state) => state.orderList);
  const { loading, orders } = orderList;

  const totalOrders = orders?.totalOrders;

  useEffect(() => {
    if (!userInfo) {
      navigate("/sign-in");
      return;
    }
    window.scrollTo(0, 0);
  }, [userInfo, navigate]);

  useEffect(() => {
    const statuses = ['All', 'ready_to_process', 'processing', 'processed', 'delivered'];
    dispatch(
      listOrderAction(
        { status: statuses[tabValue], page, limit, orderBy, order },
        userInfo.token
      )
    );
    setRefresh(false);
  }, [dispatch, tabValue, page, limit, orderBy, order, refresh, userInfo.token]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setPage(1); 
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1); 
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(1); 
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <>
      <div className={nav.isSidebar}>
        <Header nav={nav} />
        <Sidebar nav={nav} />
        <main className="page-content">
          <div className="card">
            <div className="card-body">
              <div className="d-flex flex-column">
                <Typography variant="h5" gutterBottom>
                  Orders
                </Typography>
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    mb: 2,
                  }}
                >
                  <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    aria-label="Order Status Tabs"
                    variant="scrollable"
                    scrollButtons="auto"
                    sx={{
                      '& .MuiTab-root': {
                        textTransform: 'none',
                        minWidth: 120,
                      },
                      '& .MuiTabs-indicator': {
                        backgroundColor: 'primary.main',
                      },
                      color:'white'
                    }}
                  >
                    <Tab label="All" sx={{color:'white'}} />
                    <Tab label="Ready to Process" sx={{color:'white'}} />
                    <Tab label="Processing" sx={{color:'white'}} />
                    <Tab label="Processed" sx={{color:'white'}} />
                    <Tab label="Delivered" sx={{color:'white'}} />
                  </Tabs>
                </Box>
              </div>
              {loading ? (
                <Loader />
              ) : (
                <>
                  <OrderList
                    p={orders}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    setRefresh={setRefresh} 
                  />
                  <Box display="flex" justifyContent="center" mt={2}>
                    <TablePagination
                      component="div"
                      count={totalOrders || 0}
                      page={page - 1}
                      onPageChange={handleChangePage}
                      rowsPerPage={limit}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      sx={{
                        backgroundColor: 'background.paper',
                        '& .MuiTablePagination-select': {
                          backgroundColor: 'background.paper',
                        },
                        '& .MuiTablePagination-selectIcon': {
                          color: 'text.primary',
                        },
                        '& .MuiTablePagination-actions': {
                          color: 'text.primary',
                        },
                      }}
                    />
                  </Box>
                </>
              )}
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default ListOrder;
