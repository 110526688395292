import React, { useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import fileDownload from "js-file-download";
import { Toolbar, Typography, Button, Tooltip, MenuItem, Divider, IconButton, alpha, Backdrop, CircularProgress } from "@mui/material";
import { Print as PrintIcon, Label as LabelIcon, PriceCheck as PriceCheckIcon, MoneyOff as MoneyOffIcon, BorderColor as BorderColorIcon, KeyboardArrowDown as KeyboardArrowDownIcon } from "@mui/icons-material";
import { StyledDropDownMenu } from "../../../theme/MUI/components/StyledTheme";
import { API_URI } from "../../../constants/GlobalConstants";
import { useDispatch, useSelector } from "react-redux";
import { fetchBalance, getDealerOrders } from "../../../redux/actions/DealerAction";
import EnhanceTTPaymentModal from "./EnhanceTTPaymentModal";

function EnhancedTableToolbar(props) {
  const userLogin = useSelector((state) => state.userSignIn);
  const { userInfo } = userLogin;
  const { numSelected, orderMonth, selected, month, monthVal, id, setIsPaymentInput } = props;
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modeOfPayment, setModeOfPayment] = useState('Cash');
  const [loading, setLoading] = useState(false);

  const open = Boolean(anchorEl);

  const handleClickAction = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleModeOfPaymentChange = (event) => {
    setModeOfPayment(event.target.value);
  };

  const handleActionPrint = async () => {
    try {
      const { data } = await axios.get(
        `${API_URI}/api/order/print-production-sheet/${selected}`,
        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
          responseType: "blob",
        }
      );
      fileDownload(data, `OrderSheet.pdf`);
    } catch (error) {
      alert("😭 Oop! Internal Error, contact the Admin🤓");
    }
  };

  const handleExportOrderLabel = async () => {
    try {
      const { data } = await axios.get(
        `${API_URI}/api/order/export-excel-order-label/${selected}`,
        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
          responseType: "blob",
        }
      );
      fileDownload(data, `OrderLabel.xlsx`);
    } catch (error) {
      alert("😭 Oop! Internal Error, contact the Admin🤓 Error:", error);
    }
  };

  const handleActionPaid = async (actionStatus) => {
    setLoading(true);
    const val = {
      selected,
      paymentStatusAction: actionStatus,
      modeOfPayment,
    };

    try {
      const { data } = await axios.put(
        `${API_URI}/api/order/update-payment-status`,
        val,
        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      );
      const variables = {
        id,
        monthVal,
        loadMore: false,
        month,
      };
      dispatch(getDealerOrders(variables));
      dispatch(fetchBalance(id, month));
    } catch (error) {
      alert("😭 Oop! Internal Error, contact the Admin🤓");
    } finally {
      setLoading(false);
    }
  };

  const handleSaveAsPaid = () => {
    setIsModalOpen(true);
  };

  const handleConfirmPayment = (paymentMode) => {
    handleActionPaid(true); 
  };

  return (
    <>
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.info.dark,
                theme.palette.action.activatedOpacity
              ),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: "1 1 100%" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            {orderMonth}
          </Typography>
        )}

        {numSelected > 0 ? (
          <Tooltip title="">
            <div>
              <Button
                id="demo-customized-button"
                aria-controls={open ? "demo-customized-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                variant="contained"
                disableElevation
                onClick={handleClickAction}
                endIcon={<KeyboardArrowDownIcon />}
              >
                Action
              </Button>
              <StyledDropDownMenu
                id="demo-customized-menu"
                MenuListProps={{
                  "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                <MenuItem sx={{ fontWeight: "500" }} disableRipple>
                  Payment Status
                </MenuItem>
                <MenuItem onClick={handleSaveAsPaid} disableRipple>
                  <PriceCheckIcon />
                  Save as Paid
                </MenuItem>
                <MenuItem onClick={() => handleActionPaid(false)} disableRipple>
                  <MoneyOffIcon />
                  Save as Unpaid
                </MenuItem>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem sx={{ fontWeight: "500" }} disableRipple>
                  Export Order
                </MenuItem>
                {numSelected === 1 ? (
                  <MenuItem onClick={handleActionPrint} disableRipple>
                    <PrintIcon />
                    Print Order
                  </MenuItem>
                ) : (
                  <MenuItem disabled disableRipple>
                    <PrintIcon />
                    Print Order
                  </MenuItem>
                )}
                {numSelected === 1 ? (
                  <MenuItem onClick={handleExportOrderLabel} disableRipple>
                    <LabelIcon />
                    Export Label
                  </MenuItem>
                ) : (
                  <MenuItem disabled disableRipple>
                    <LabelIcon />
                    Export Label
                  </MenuItem>
                )}
                <Divider sx={{ my: 0.5 }} />
                <MenuItem sx={{ fontWeight: "500" }} disableRipple>
                  Payment Section
                </MenuItem>
                {numSelected === 1 ? (
                  <MenuItem
                    onClick={() => (
                      setAnchorEl(null),
                      setIsPaymentInput(true),
                      window.scrollTo(0, document.body.scrollHeight)
                    )}
                    disableRipple
                  >
                    <BorderColorIcon />
                    Edit Payment
                  </MenuItem>
                ) : (
                  <MenuItem disabled disableRipple>
                    <BorderColorIcon />
                    Edit Payment
                  </MenuItem>
                )}
              </StyledDropDownMenu>
            </div>
          </Tooltip>
        ) : (
          <Tooltip title="Filter list">
            <IconButton>{/* <FilterListIcon /> */}</IconButton>
          </Tooltip>
        )}
      </Toolbar>

      <EnhanceTTPaymentModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleConfirmPayment}
        modeOfPayment={modeOfPayment}
        handleModeOfPaymentChange={handleModeOfPaymentChange}
      />
      
      {/* Loader Modal */}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  orderMonth: PropTypes.string.isRequired,
  selected: PropTypes.arrayOf(PropTypes.number).isRequired,
  setLoading: PropTypes.func.isRequired,
  setIsPaymentInput: PropTypes.func.isRequired,
  renderDispatch: PropTypes.func,
};

export default EnhancedTableToolbar;
