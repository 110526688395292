import { nanoid } from "nanoid"
import { fauxwoodProduction } from "../../../functions/productionSheet/fauxwood/FauxwoodProduction"
import { cvrtDeci2Frac, getDecimal, getFinalVal, totalDecimal } from "../../../functions/productionSheet/GolbalFunctions"
import { rollerEquation } from "../../../functions/productionSheet/rollerShades/RollerProduction"
import { verticalProduction } from "../../../functions/productionSheet/vertical/verticalProduction"

//| ---------------------UPDATE------------------------

// This update single order inside Arr by finding the index
export const updateOrderArr = ({ OrderArr, editProductId, productType, widthEdit, heightEdit, remarkEdit, editLocation, headrail }) => {
    const newOrder = [...OrderArr]
    const index = OrderArr.findIndex((o) => o.id === editProductId)
    switch (productType) {
        case 'Zebra_blinds':
        case 'Roller_shades':
            const { fascia, tube, fabric, sqft } = rollerEquation(widthEdit, heightEdit, headrail)
            newOrder[index] = {
                id: editProductId,
                width: widthEdit,
                height: heightEdit,
                // control: controlEdit,
                // lift: liftEdit,
                // mount: mountEdit,
                remark: remarkEdit, location: editLocation,
                fascia, tube, fabric, factor: sqft
            }
            // setOrder(newOrder)
            // seteditProductId(null)
            return newOrder
            break

        case 'Fauxwood':
            const { readyWidth, slat, fullPrice } = fauxwoodProduction(widthEdit, heightEdit)
            newOrder[index] = {
                id: editProductId,
                width: widthEdit,
                height: heightEdit,
                // control: controlEdit,
                // lift: liftEdit,
                // mount: mountEdit,
                remark: remarkEdit, location: editLocation,
                readyWidth, slat, factor: fullPrice
            }
            // setOrder(newOrder)
            // seteditProductId(null)
            return newOrder
            break

        case 'Vertical_blinds':
            const { chain, cord, track, vanes, vaneDed, pvc, vfullPrice } = verticalProduction(widthEdit, heightEdit)
            newOrder[index] = {
                id: editProductId,
                width: widthEdit,
                height: heightEdit,
                // control: controlEdit,
                // lift: liftEdit,
                // mount: mountEdit,
                remark: remarkEdit, location: editLocation,
                chain, cord, track, vanes, vaneDed, pvc, factor: vfullPrice
            }

            return newOrder
            break

        default:
            return null
    }
}

// Update the overall order factor
export const updateOrderFactor = (orderArr) => {
    return (orderArr.reduce((n, { factor }) => n + factor, 0))
}

// Delete the order by id from order Arr
export const deleteOrderbyId = (orderId, orderArr) => {
    const newOrder = [...orderArr]
    const index = orderArr.findIndex((o) => o.id === orderId)
    newOrder.splice(index, 1);
    console.log(newOrder)
    return (newOrder)
}

//* -------------------Order Calculation 🧮-------------------
export function getProductionData(productType, width, height, control, lift, mount, remark, location, headrail, deduction, addition) {
    //Finding out if there is any attribute need to calculate
    var newWidth = width
    if (deduction) {
        newWidth = getFinalVal(totalDecimal(width) - getDecimal(deduction))
    } else if (addition) {
        newWidth = getFinalVal(totalDecimal(width) + getDecimal(addition))
    }
    if (productType === 'Fauxwood') {
        const { readyWidth, slat, fullPrice } = fauxwoodProduction(newWidth, height)
        const newOrder = {
            id: nanoid(),
            width: newWidth, height,
            lift, remark, location,
            readyWidth, slat, factor: fullPrice
        };
        return newOrder;
    }
    else if (productType === 'Vertical_blinds') {
        const { chain, cord, track, vanes, vaneDed, pvc, vfullPrice } = verticalProduction(newWidth, height, headrail)
        const newOrder = {
            id: nanoid(),
            width: newWidth, height, remark,
            chain, cord, track, vanes, vaneDed, pvc, factor: vfullPrice,
        };
        return newOrder;
    }
    else if (productType === 'Zebra_blinds' || 'Roller_shades') {
        const { fascia, tube, fabric, sqft } = rollerEquation(newWidth, height, headrail)
        const newOrder = {
            id: nanoid(),
            width: newWidth, height,
            lift, remark, location,
            fascia, tube, fabric, factor: sqft
        };
        return newOrder;
    }
}

//+ -----------------Attributes:Order Attributes Functions---------------
//This function will split the left,right and middle splits and returns the l,r,m values
export function splitValidation(width, name, value, setSplitWidth, splitWidth) {
    var decWidth = totalDecimal(width)
    if (name === 'l') {
        setSplitWidth({ ...splitWidth, l: value, r: cvrtDeci2Frac(decWidth - totalDecimal(value)), m: 0 })
    } else if (name === 'r') {
        var right = totalDecimal(value)
        setSplitWidth({ ...splitWidth, r: value, m: cvrtDeci2Frac(decWidth - (right + totalDecimal(splitWidth.l))) })
    }
}