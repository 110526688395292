import React, { useEffect } from "react";
import { Card, CardContent, Typography, CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fetchBalance } from "../../../redux/actions/DealerAction";

const AccountSection = ({ dealerId, month, refresh }) => {
  const dispatch = useDispatch();
  const balance = useSelector((state) => state.outStandingBalance);
  const { currentMonthOutstanding, overallTotalOutstanding, loading, error } = balance;
  const userLogin = useSelector((state) => state.userSignIn);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (dealerId !== undefined && month !== undefined && userInfo?.token) {
      dispatch(fetchBalance(dealerId, month));
    }
  }, [dealerId, month, refresh, userInfo?.token, dispatch]);

  const getMonthNameFromOffset = (monthOffset) => {
    const now = new Date();
    const targetDate = new Date(now.getFullYear(), now.getMonth() - monthOffset, 1);
    return targetDate.toLocaleString("default", { month: "long" });
  };

  if (loading) {
    return (
      <div className="mt-3 px-1">
        <CircularProgress sx={{ color: "#ffffff" }} />
      </div>
    );
  }

  if (error) {
    return (
      <div className="mt-3 px-1">
        <Typography variant="h6" component="div" sx={{ color: "#ff0000" }}>
          Error: {error}
        </Typography>
      </div>
    );
  }

  const monthName = getMonthNameFromOffset(month);

  return (
    <div className="mt-3 px-1">
      <div className="breadcrumb-title pe-3 my-3">Account Section</div>
      <div className="col justify-content-center">
        <div className="col-md-6 mb-3 px-1">
          <Card
            sx={{
              border: "2px solid #007bff",
              borderRadius: "35px",
              backgroundColor: "#1e1e2d",
              color: "#ffffff",
              height: "auto",
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "16px",
                paddingBottom: "0px",
              }}
            >
              <Typography variant="h6" component="div">
                Outstanding Amount in{" "} {monthName}
              </Typography>
              <Typography
                variant="h5"
                component="div"
                sx={{ fontWeight: "bold" }}
              >
                ${currentMonthOutstanding ? currentMonthOutstanding.toFixed(2) : "0.00"}
              </Typography>
            </CardContent>
          </Card>
        </div>
        <div className="col-md-6 px-1">
          <Card
            sx={{
              border: "2px solid #007bff",
              borderRadius: "35px",
              backgroundColor: "#1e1e2d",
              color: "#ffffff",
              height: "auto",
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "16px",
                paddingBottom: "0px",
              }}
            >
              <Typography variant="h6" component="div">
                Overall Balance
              </Typography>
              <Typography
                variant="h5"
                component="div"
                sx={{ fontWeight: "bold" }}
              >
                ${overallTotalOutstanding ? overallTotalOutstanding.toFixed(2) : "0.00"}
              </Typography>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default AccountSection;
