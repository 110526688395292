import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { ResetPwdModel } from "../../components/dealer/profile/ResetPwdModel";
import Header from "../../components/layout/Header";
import Loader from "../../components/layout/Loader";
import LoaderLin from "../../components/layout/LoaderLin";
import Sidebar from "../../components/layout/Sidebar";
import {
  ViewDealerAction,
  updateDealerProfile,
} from "../../redux/actions/DealerAction";
import ProductPriceModal from "../../components/dealer/profile/ProductPriceModal";
import ReusableAlert from "../../components/layout/alert/AlertMessage";

const Profile = ({ nav }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const userLogin = useSelector((state) => state.userSignIn);
  const { error, userInfo } = userLogin;
  const dealerDetail = useSelector((state) => state.viewDealer);
  const { loading, dealer, success: detailSuccess } = dealerDetail;
  const dealerUpdate = useSelector((state) => state.dealerUpdate);
  const {
    loading: updateLoading,
    success: updateSuccess,
    error: updateError,
  } = dealerUpdate;

  const [formData, setFormData] = useState({
    username: "",
    email: "",
    phone: "",
    address: "",
    companyName: "",
    password: "",
  });

  const [open, setOpen] = useState(false);
  const [priceModalOpen, setPriceModalOpen] = useState(false);
  const [initialData, setInitialData] = useState({});
  const [alert, setAlert] = useState({
    title: "",
    description: "",
    variant: "",
  });

  useEffect(() => {
    if (dealer) {
      setFormData({
        username: dealer.username,
        email: dealer.email,
        phone: dealer.phone,
        address: dealer.address,
        companyName: dealer.companyName,
        password: "",
      });
      setInitialData(dealer.productRate || {});
    }
  }, [dealer]);

  useEffect(() => {
    dispatch({ type: "DEALER_DETAIL_RESET" });
    if (!userInfo) {
      navigate("/sign-in");
    }
    window.scrollTo(0, 0);
    if (window.innerWidth > 1200) {
      nav.setIsSidebar("wrapper toggled");
    }
  }, [userInfo, navigate]);

  useEffect(() => {
    dispatch({ type: "DEALER_DETAIL_RESET" });
    dispatch(ViewDealerAction(id, userInfo.token));
  }, [id, dispatch, userInfo]);

  useEffect(() => {
    if (updateError) {
      setAlert({
        title: "Error",
        description: updateError,
        variant: "error",
      });
    }
    if (updateSuccess) {
      setAlert({
        title: "Success",
        description: "Profile Updated Successfully",
        variant: "success",
      });
    }
  }, [updateError, updateSuccess]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateDealerProfile(id, formData, userInfo.token));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePriceModalSave = (updatedPrices) => {
    const updatedFormData = { ...formData, productRate: updatedPrices };
    setFormData(updatedFormData);
    dispatch(updateDealerProfile(id, updatedFormData, userInfo.token)).then(
      () => {
        dispatch(ViewDealerAction(id, userInfo.token));
        setPriceModalOpen(false);
      }
    );
  };

  const handleAlertClose = () => {
    setAlert({ title: "", description: "", variant: "" });
  };
  return (
    <div className={nav.isSidebar}>
      <Header nav={nav} />
      <Sidebar nav={nav} />
      <main className="page-content">
         {/*breadcrumb*/}
        <div className="page-breadcrumb d-none d-sm-flex align-items-center">
          <div className="breadcrumb-title pe-3 text-white">Pages</div>
          <div className="ps-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0 p-0">
                <li className="breadcrumb-item">
                  <a href="javascript:;">
                    <i className="bx bx-home-alt text-white" />
                  </a>
                </li>
                <li
                  className="breadcrumb-item active text-white"
                  aria-current="page"
                >
                  User Profile
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="profile-cover bg-dark" />
        {ResetPwdModel(open, setOpen)}
        <div className="row">
          {loading ? (
            <Loader />
          ) : (
            <div className="col-12 col-lg-8">
              <div className="card shadow-sm border-0">
                <div className="card-body">
                  <h5 className="mb-0">My Account</h5>
                  <hr />
                  {updateLoading && <Loader />}
                  <form onSubmit={handleSubmit} className="row g-3">
                    <div className="card shadow-none border">
                      <div className="card-header">
                        <h6 className="mb-0">USER INFORMATION</h6>
                      </div>
                      <div className="card-body">
                        <div className="row g-3">
                          <div className="col-6">
                            <label className="form-label">Username</label>
                            <input
                              type="text"
                              className="form-control"
                              name="username"
                              value={formData.username}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-6">
                            <label className="form-label">Email address</label>
                            <input
                              type="text"
                              className="form-control"
                              name="email"
                              value={formData.email}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card shadow-none border">
                      <div className="card-header">
                        <h6 className="mb-0">CONTACT INFORMATION</h6>
                      </div>
                      <div className="card-body">
                        <div className="row g-3">
                          <div className="col-12">
                            <label className="form-label">Phone</label>
                            <input
                              type="text"
                              className="form-control"
                              name="phone"
                              value={formData.phone}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-12">
                            <label className="form-label">Address</label>
                            <input
                              type="text"
                              className="form-control"
                              name="address"
                              value={formData.address}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card shadow-none border">
                      <div className="card-header">
                        <h6 className="mb-0">Update Setting</h6>
                      </div>
                      <div className="card-body">
                        <div className="row g-3">
                          <div className="col-12">
                            <label className="form-label">Company Name</label>
                            <input
                              type="text"
                              className="form-control"
                              name="companyName"
                              value={formData.companyName}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-12">
                            <label className="form-label">Password</label>
                            <input
                              type="password"
                              className="form-control"
                              name="password"
                              value={formData.password}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-12">
                            <button
                              type="submit"
                              className="btn btn-sm btn-outline-info col-12"
                            >
                              <i className="fadeIn animated bx bx-lock" />
                              Update Profile
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}
          <div className="col-12 col-lg-4">
            <div className="card shadow-sm border-0 overflow-hidden">
              <div className="card-body">
                <div className="text-center mt-4">
                  <h6 className="mb-1">
                    {dealer ? dealer.companyName : <LoaderLin />}
                  </h6>
                  <p className="mb-0 text-secondary">Authorized Dealer</p>
                </div>
                <hr />
                <div className="text-start">
                  <h5 className="mb-3">Contact Information</h5>
                  <div className="row mb-3">
                    <div className="col-12">
                      <p className="mb-0 text-secondary">
                        <i className="fadeIn animated bx bx-phone me-2" />
                        {dealer ? dealer.phone : <LoaderLin />}
                      </p>
                    </div>
                    <div className="col-12">
                      <p className="mb-0 text-secondary">
                        <i className="fadeIn animated bx bx-envelope me-2" />
                        {dealer ? dealer.email : <LoaderLin />}
                      </p>
                    </div>
                  </div>
                  <h5 className="mb-3">Location</h5>
                  <div className="row mb-3">
                    <div className="col-12">
                      <p className="mb-0 text-secondary">
                        <i className="fadeIn animated bx bx-map me-2" />
                        {dealer ? dealer.address : <LoaderLin />}
                      </p>
                    </div>
                  </div>
                  <h5 className="mb-3">Product Rates</h5>
                  <div className="row mb-3">
                    {dealer && dealer.productRate ? (
                      Object.entries(dealer.productRate).map(
                        ([product, price]) => (
                          <div className="col-12" key={product}>
                            <p className="mb-0 text-secondary">
                              <i className="fadeIn animated bx bx-dollar me-2" />
                              {product}: ${price}
                            </p>
                          </div>
                        )
                      )
                    ) : (
                      <LoaderLin />
                    )}
                  </div>
                  <button
                    className="btn btn-sm btn-outline-info"
                    onClick={() => setPriceModalOpen(true)}
                  >
                    Update Product Prices
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {priceModalOpen && (
          <ProductPriceModal
            open={priceModalOpen}
            handleClose={() => setPriceModalOpen(false)}
            handleSave={handlePriceModalSave}
            initialData={initialData}
          />
        )}
        <ReusableAlert
          title={alert.title}
          description={alert.description}
          variant={alert.variant}
          onClose={handleAlertClose}
        />
      </main>
    </div>
  );
};

export default Profile;
