import {
    DEALER_CREATE_FAIL, DEALER_CREATE_REQUEST, DEALER_CREATE_SUCCESS,
    DEALER_LIST_FAIL, DEALER_LIST_REQUEST, DEALER_LIST_SUCCESS,
    DEALER_UPDATE_FAIL,
    DEALER_UPDATE_REQUEST,
    DEALER_UPDATE_SUCCESS,
    GET_BALANCE_FAILURE,
    GET_BALANCE_REQUEST,
    GET_BALANCE_SUCCESS,
    GET_DEALER_ORDER_FAILURE,
    GET_DEALER_ORDER_REQUEST,
    GET_DEALER_ORDER_SUCCESS
} from "../../constants/DealerConstant";

export const DealerCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case DEALER_CREATE_REQUEST:
            return { loading: true, dealer: [] }
        case DEALER_CREATE_SUCCESS:
            return { loading: false, success: true, dealer: action.payload }
        case DEALER_CREATE_FAIL:
            return { loading: false, error: action.payload }
        case 'DEALER_CREATE_RESET':
            return {}
        default:
            return state;
    }
}

export const DealerListReducer = (state = {}, action) => {
    switch (action.type) {
        case DEALER_LIST_REQUEST:
            return { loading: true, dealer: [] }
        case DEALER_LIST_SUCCESS:
            return { loading: false, success: true, dealer: action.payload }
        case DEALER_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state;
    }
}

export const ViewDealerReducer = (state = {}, action) => {
    switch (action.type) {
        case 'DEALER_DETAIL_REQUEST':
            return { loading: true, dealer: [] }
        case 'DEALER_DETAIL_SUCCESS':
            return { loading: false, success: true, dealer: action.payload }
        case 'DEALER_DETAIL_FAIL':
            return { loading: false, error: action.payload }
        case 'DEALER_DETAIL_RESET':
            return {}
        default:
            return state;
    }
}

export const UpdateDealerNotesReducer = (state = {}, action) => {
    switch (action.type) {
        case 'DEALER_UPDATE_NOTE_REQUEST':
            return { loading: true, dealer: [] }
        case 'DEALER_UPDATE_NOTE_SUCCESS':
            return { loading: false, success: true }
        case 'DEALER_UPDATE_NOTE_FAIL':
            return { loading: false, error: action.payload }
        case 'DEALER_UPDATE_NOTE_RESET':
            return {}
        default:
            return state;
    }
}

//-----------------------Dealer Order-------------------------
export const DealerOrderStatsReducer = (state = {}, action) => {
    switch (action.type) {
        case 'DEALER_ORDER_STAT_REQUEST':
            return { loading: true, dealer: [] }
        case 'DEALER_ORDER_STAT_SUCCESS':
            return { loading: false, success: true, stats: action.payload }
        case 'DEALER_ORDER_STAT_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state;
    }
}

//----------------ordersList--------------------------


const orderState = {
    loading: false,
    orderMonth: null,
    rows: [],
    error: null,
  };
  
  export const DealerOrderReducer = (state = orderState, action) => {
    switch (action.type) {
      case GET_DEALER_ORDER_REQUEST:
        return { ...state, loading: true };
      case GET_DEALER_ORDER_SUCCESS:
        return {
          ...state,
          loading: false,
          orderMonth: action.payload.orderMonth,
          rows: action.payload.rows,
        };
      case GET_DEALER_ORDER_FAILURE:
        return { ...state, loading: false, error: action.payload };
      default:
        return state;
    }
  };


  const balanceState = {
    currentMonthOutstanding: 0,
    overallTotalOutstanding: 0,
    loading: false,
    error: null,
  };
  // Oustanding Balance 

  const balanceReducer = (state = balanceState, action) => {
    switch (action.type) {
      case GET_BALANCE_REQUEST:
        return { ...state, loading: true };
      case GET_BALANCE_SUCCESS:
        return {
          ...state,
          loading: false,
          currentMonthOutstanding: action.payload.currentMonthOutstanding,
          overallTotalOutstanding: action.payload.overallTotalOutstanding,
          error: null,
        };
      case GET_BALANCE_FAILURE:
        return { ...state, loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export default balanceReducer;

  // Update dealer

  export const dealerUpdateReducer = (state = {}, action) => {
    switch (action.type) {
      case DEALER_UPDATE_REQUEST:
        return { loading: true };
      case DEALER_UPDATE_SUCCESS:
        return { loading: false, success: true, dealer: action.payload };
      case DEALER_UPDATE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };