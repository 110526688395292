export const ProductInsertion = ({ setInsertion, insertion }) => {
    const handleInsertionInput = (e) => {
        //getting values from user input
        const { name, value } = e.target
        setInsertion({ ...insertion, [name]: value })
    }
    return (
        <>

            <div className="col-2">
                <label className="form-label">Insertion</label>
                <input type="number" className="form-control" placeholder="Row Index" name='val'
                    min="0"
                    value={insertion.val} onChange={handleInsertionInput}
                />
            </div>
            {/* <div className="col-1 mt-4 mx-4 " >
                <form
                // onSubmit={ }
                >
                    <button type='submit'
                        // onSubmit={handleSplitSubmit}
                        className="mt-1 btn btn-success">
                        Insert
                    </button>
                </form>
            </div> */}
            <div className="col-1 mt-4 float-right" >
                <form>
                    <button type='submit'
                        onClick={(e) => setInsertion({ trigger: false })}
                        className="mt-1 btn btn-danger">
                        Cancel
                    </button>
                </form>
            </div>
        </>
    )
}