import axios from "axios"
import { API_URI } from "../../constants/GlobalConstants"

export const AnnualSaleAnalAction = ({companyName,token}) => async (dispatch) => {
    console.log("🚀 ~ file: AnalyticsActions.js:5 ~ AnnualSaleAnalAction ~ dealerName:", companyName)
    try {
        dispatch({ type: 'ANAL_ANNUAL_SALES_REQUEST' })
        const { data } = await axios.post(`${API_URI}/api/analytics/annual-sales`, {companyName},
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
        dispatch({ type: 'ANAL_ANNUAL_SALES_SUCCESS', payload: data })
    } catch (error) {
        dispatch({
            type: 'ANAL_ANNUAL_SALES_FAIL',
            payload: error.response && error.response.data.message ?
                error.response.data.message : error.message
        })
    }
}