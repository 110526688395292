import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../components/layout/Header'
import Sidebar from '../../components/layout/Sidebar'
import { Link } from 'react-router-dom';
import AddAttribute from '../../components/productManagement/AddProduct/AddAttribute';
import { createOrderAction, listAttributeAction } from '../../redux/actions/ProductActions';
import LoaderLin from '../../components/layout/LoaderLin';


const AddProductAttribute = ({ nav }) => {
    const dispatch = useDispatch()
    const userLogin = useSelector((state) => state.userSignIn);
    const { userInfo } = userLogin;

    const [isCreate, setIsCreate] = useState({
        section: '',
        show: false
    });
    const [productType, setProductType] = useState({
        name: ''
    });
    const [selectedAttri, setSelectedAttri] = useState({
        productType: null,
        lift: null,
        valance: null,
        material: null,
        bottom: null,
        addon: null,
    });

    const [newAttri, setNewAttri] = useState('');

    const createProduct = useSelector(state => state.createProduct)
    const { loading: createLoading, data: createData, success: createSuccess } = createProduct
    const productAtrri = useSelector(state => state.listAttribute)
    const { loading: attriLoading, data: attributes, success: atrriSuccess } = productAtrri

    function submitAttribute(val) {
        console.log("Submit fdsdfsdfsf", val)
        dispatch(createOrderAction({
            attributeType: val.attributeType,
            name: val.attriName,
            defaultPrice: '',
            parentProduct: val.productType,
            slug: ''
        },userInfo.token))
        setIsCreate({
            section: '',
            show: false
        })
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        if (window.innerWidth > 1200) {
            nav.setIsSidebar('wrapper toggled')
        }
        const slug = `?productType=${selectedAttri.productType}&lift=${selectedAttri.lift}&valance=${selectedAttri.valance}&material=${selectedAttri.material}&bottom=${selectedAttri.bottom}&addon=${selectedAttri.addon}`
        dispatch(listAttributeAction(slug,userInfo.token))
        setSelectedAttri({ ...selectedAttri, slug: '' })
    }, [createSuccess]);

    const fetchAttri = () => {
        console.log("did you called me?")
        if (selectedAttri.slug !== '') {
            const slug = `?productType=${selectedAttri.productType}&lift=${selectedAttri.lift}&valance=${selectedAttri.valance}&material=${selectedAttri.material}&bottom=${selectedAttri.bottom}&addon=${selectedAttri.addon}`
            dispatch(listAttributeAction(slug,userInfo.token))
            setSelectedAttri({ ...selectedAttri, slug: '' })
        }

    }

    console.log("selected Attri", selectedAttri)


    return (
        <>
            <div className={nav.isSidebar}>
                <Header nav={nav} />
                <Sidebar nav={nav} />
                <main className="page-content">
                    {/*breadcrumb*/}
                    <div className="page-breadcrumb d-none d-sm-flex align-items-center">
                        <div className="breadcrumb-title pe-3 text-white">Product Management</div>
                        <div className="ps-3">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item">
                                        <Link to='/'>
                                            <i className="bx bx-home-alt text-white" />
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item active text-white" aria-current="page">
                                        Add Product/Attributes
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    {/*end breadcrumb*/}
                    <div className="product-cover bg-dark" />
                    <div className="row">
                        <div className="col-12 col-lg-8">
                            <div className="card shadow-sm border-0">
                                <div className="card-body">
                                    <h5 className="mb-0">Add Product/Product Attributes</h5>
                                    <hr />
                                    {/* Product Type */}
                                    {createLoading || attriLoading ? <LoaderLin /> : (
                                        <>
                                            <AddAttribute para={{
                                                title: 'Product Type', attriName: 'productType', isCreate, setIsCreate,
                                                submitAttribute, selectedAttri, setSelectedAttri,
                                                attributes, newAttri, setNewAttri, dispatch, listAttributeAction
                                            }} />
                                        </>
                                    )}
                                    {/* Lift Style */}
                                    {selectedAttri.productType ? (createLoading || attriLoading ? <LoaderLin /> : (

                                        <>
                                            <AddAttribute para={{
                                                title: 'Lift Style', attriName: 'lift', isCreate, setIsCreate,
                                                submitAttribute, selectedAttri, setSelectedAttri,
                                                attributes, newAttri, setNewAttri, dispatch, listAttributeAction
                                            }} />
                                        </>
                                    )) : <></>}
                                    {/* Valance */}
                                    {selectedAttri.lift ? (createLoading || attriLoading ? <LoaderLin /> :
                                        (<AddAttribute para={{
                                            title: 'Valance/Headrail', attriName: 'valance', isCreate, setIsCreate,
                                            submitAttribute, selectedAttri, setSelectedAttri,
                                            attributes, newAttri, setNewAttri
                                        }} />)) : <></>}
                                    {/* Valance */}
                                    {selectedAttri.valance ? (createLoading || attriLoading ? <LoaderLin /> :
                                        (<AddAttribute para={{
                                            title: 'Fabric/Material', attriName: 'material', isCreate, setIsCreate,
                                            submitAttribute, selectedAttri, setSelectedAttri,
                                            attributes, newAttri, setNewAttri
                                        }} />)) : <></>}
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4">
                            <div className="card shadow-sm border-0 overflow-hidden">
                                <div className="card-body">
                                    <div className="text-center mt-4">
                                        <div className="mt-4" />
                                        <h6 className="mb-1">
                                            dfsfs</h6>
                                        <p className="mb-0 text-secondary">
                                            Authorized Dealer
                                        </p>
                                    </div>
                                    <hr />
                                    <div className="text-start">
                                        <h5 className="">Messages</h5>
                                        <p className="mb-0 text-muted">
                                            There is no messages 🤓!
                                        </p>
                                    </div>
                                </div>
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item d-flex justify-content-between align-items-center bg-transparent border-top">
                                        Reminder
                                        <span className="badge bg-primary rounded-pill">0</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center bg-transparent">
                                        Messages
                                        <span className="badge bg-primary rounded-pill">0</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center bg-transparent">
                                        Cancelation
                                        <span className="badge bg-primary rounded-pill">0</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/*end row*/}
                </main>
            </div>
        </>
    )
}

export default AddProductAttribute