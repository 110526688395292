import { cvrtDeci2Frac, getDecimal, getFinalVal, nearestGreaterVal, totalDecimal } from "../GolbalFunctions";
import { heightArray, widthArray, wMatrix } from "./fauxwoodMatrix";


export function fauxwoodProduction(w, h) {
    // console.log("FauxwoodProduction.js:6 ~ deduction:", (totalDecimal(w) - getDecimal(deduction)))
    const wFraction = totalDecimal(w)
    const hFraction = totalDecimal(h)
    const wNearest = nearestGreaterVal(widthArray, wFraction)
    const hNearest = nearestGreaterVal(heightArray, hFraction)
    //ready width = -3/8
    let readyWidth = wFraction - .375
    //Slat = Height/1.75 
    let slat = Math.round(hFraction / 1.75)
    // Full price is the Matrix intesect
    const fullPrice = wMatrix[widthArray.indexOf(wNearest)][heightArray.indexOf(hNearest)]
    return (
        {
            readyWidth: getFinalVal(readyWidth),
            slat,
            fullPrice
        }
    )
}

