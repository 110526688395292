import axios from "axios";
import { API_URI } from "../../constants/GlobalConstants";

export const createDealerOrderAction = (val, token) => async (dispatch) => {
  try {
    dispatch({ type: "DEALER_ORDER_CREATE_REQUEST" });
    const { data } = await axios.post(
      `${API_URI}/api/order/create-dealer-order`,
      val,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob",
      }
    );
    dispatch({ type: "DEALER_ORDER_CREATE_SUCCESS", payload: data });
  } catch (error) {
    dispatch({
      type: "DEALER_ORDER_CREATE_FAIL",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listOrderAction =
  ({ status = "All", page = 1, limit = 10 },token) =>
  async (dispatch) => {
    try {
      dispatch({ type: "ORDER_LISTS_REQUEST" });
      const { data } = await axios.get(`${API_URI}/api/order/list`, {
        params: { status, page, limit  },
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      dispatch({ type: "ORDER_LISTS_SUCCESS", payload: data });
    } catch (error) {
      dispatch({
        type: "ORDER_LISTS_FAIL",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const OrderDetailAction = (id, token) => async (dispatch) => {
  try {
    dispatch({ type: "ORDER_DETAIL_REQUEST" });
    const { data } = await axios.get(
      `${API_URI}/api/order/order-detail/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({ type: "ORDER_DETAIL_SUCCESS", payload: data });
  } catch (error) {
    dispatch({
      type: "ORDER_DETAIL_FAIL",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const OrderDeleteAction = (id, token) => async (dispatch) => {
  try {
    dispatch({ type: "ORDER_DELETE_REQUEST" });
    //passing the token

    await axios.delete(`${API_URI}/api/order/delete-order/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({ type: "ORDER_DELETE_SUCCESS" });
  } catch (error) {
    dispatch({
      type: "ORDER_DELETE_FAIL",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const OrderByDealerIDAction = (form,token) => async (dispatch) => {
  try {
    dispatch({ type: "ORDER_BY_DEALER_ID_REQUEST" });
    //passing the token

    const { data } = await axios.post(
      `${API_URI}/api/order/order-by-dealer?month=${form.month}`,
      form,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    dispatch({ type: "ORDER_BY_DEALER_ID_SUCCESS", payload: data });
  } catch (error) {
    dispatch({
      type: "ORDER_BY_DEALER_ID_FAIL",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const UpdateOrderAction = (form,token) => async (dispatch) => {
  try {
    dispatch({ type: "ORDER_UPDATE_REQUEST" });
    const { data } = await axios.put(
      `${API_URI}/api/order/update-order`,
      form,
      {
        headers: {
          Authorization: `Bearer ${token}`
        },
        responseType: "blob",
      }
      
    );
    dispatch({ type: "ORDER_UPDATE_SUCCESS", payload: data });
  } catch (error) {
    dispatch({
      type: "ORDER_UPDATE_FAIL",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

//=========================Public======================================
export const CreateOrderAction = (form,token) => async (dispatch) => {
  try {
    dispatch({ type: "ORDER_CREATE_REQUEST" });
    const { data } = await axios.post(`${API_URI}/api/order/create`, form,{
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    dispatch({ type: "ORDER_CREATE_SUCCESS", payload: data });
  } catch (error) {
    dispatch({
      type: "ORDER_CREATE_FAIL",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

