import { getFinalVal, nearestGreaterVal, totalDecimal } from "../GolbalFunctions"
import { heightArray, wMatrix, widthArray } from "./verticalMatrix"

export function verticalProduction(w, h) {
    const wDecimal = totalDecimal(w)
    const hDecimal = totalDecimal(h)
    const wNearest = nearestGreaterVal(widthArray, wDecimal)
    const hNearest = nearestGreaterVal(heightArray, hDecimal)

    let chain = Math.round(hDecimal * .66)
    let cord = Math.round(wDecimal + chain)
    let track = getFinalVal(wDecimal - 1.625)
    let vanes = Math.round(wDecimal / 3.075)
    let vaneDed = getFinalVal(hDecimal - 2)
    let pvc = Math.round((totalDecimal(vaneDed) - 2.625 + 4) * vanes / 145)
    const vfullPrice = wMatrix[widthArray.indexOf(wNearest)][heightArray.indexOf(hNearest)]
    return (
        {
            chain, cord, track, vanes, vaneDed, pvc, vfullPrice
        }
    )
}