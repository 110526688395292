import React from "react";
import { BrowserRouter, Route, Routes, Link } from "react-router-dom";
import ListAppointment from "./pages/appointmentManagement/ListAppointment";

import "./App.css";
import Home from "./pages/Home";
import AddClient from "./pages/clientManagement/AddClient";
import ClientList from "./pages/clientManagement/ClientList";
import AddAppointment from "./pages/appointmentManagement/AddAppointment";
import SignIn from "./pages/auth/SignIn";
import DetailAppointment from "./pages/appointmentManagement/DetailAppointment";
import EditAppointment from "./pages/appointmentManagement/EditAppointment";
import CreateDealer from "./pages/dealerManagement/CreateDealer";
import ListDealer from "./pages/dealerManagement/ListDealer";
import AddOrder from "./pages/orderManagement/AddOrder";
import InvoiceApp from "./apps/invoiceGenerator/InvoiceApp";
import AddAction from "./pages/actionManagement/AddAction";
import ViewAction from "./pages/actionManagement/ViewAction";
import OrderPage from "./public/page/OrderPage";
import ListOrder from "./pages/orderManagement/ListOrder";
import DetailOrder from "./pages/orderManagement/DetailOrder";
import ListInventory from "./pages/inventoryManagement/ListInventory";
import EditDealer from "./pages/dealerManagement/EditDealer";
import ViewDealer from "./pages/dealerManagement/ViewDealer";
import AccountView from "./pages/dealerManagement/AccountView";
import { useState } from "react";
import SalesAnalytics from "./pages/analytics/sales/SalesAnalytics";
import ProductionAnalytics from "./pages/analytics/productions/ProductionAnalytics";
import Profile from "./pages/dealerManagement/Profile";
import AddProductAttribute from "./pages/productManagement/AddProductAttribute";
import AddInventoryItem from "./pages/inventoryManagement/AddInventoryItem";
import ViewInventoryItem from "./pages/inventoryManagement/ViewInventoryItem";
import CreateUser from "./pages/userManagement/CreateUser";
import ManageUser from "./pages/userManagement/ManageUser";
import PrivateRoute from "./components/layout/PrivateRoute";
import EditUser from "./pages/userManagement/Edituser";
import AppointmentDashboard from "./pages/appointmentManagement/AppointmentDashboard";
import TransactionHistory from "./pages/dealerManagement/TransactionHistory";
import Error404 from "./components/layout/Error404";

const App = () => {
  const [isSidebar, setIsSidebar] = useState("wrapper");
  const navProps = { isSidebar, setIsSidebar };
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home nav={{ isSidebar, setIsSidebar }} />} />
        {/* Authentication */}
        <Route path="/sign-in" element={<SignIn />} />
        {/* Actions */}
        <Route
          path="/action/add-action"
          element={
            <PrivateRoute
              element={AddAction}
              roles={["admin", "inhouse_installer"]}
              nav={{ isSidebar, setIsSidebar }}
            />
          }
        />
        <Route
          path="/action/list-action"
          element={
            <PrivateRoute
              element={ViewAction}
              roles={["admin", "inhouse_installer"]}
              nav={{ isSidebar, setIsSidebar }}
            />
          }
        />
        {/* Appointment Management Routes */}
        <Route
          path="/appointments"
          element={
            <PrivateRoute
              element={ListAppointment}
              roles={["admin", "inhouse_installer"]}
              nav={{ isSidebar, setIsSidebar }}
            />
          }
        />
        <Route
          path="/add-appointment"
          element={
            <PrivateRoute
              element={AddAppointment}
              roles={["admin", "inhouse_installer"]}
              nav={{ isSidebar, setIsSidebar }}
            />
          }
        />
        <Route
          path="/detail-appointment/:id"
          element={
            <PrivateRoute
              element={DetailAppointment}
              roles={["admin", "inhouse_installer"]}
              nav={{ isSidebar, setIsSidebar }}
            />
          }
        />
        <Route
          path="/edit-appointment/:id"
          element={
            <PrivateRoute
              element={EditAppointment}
              roles={["admin", "inhouse_installer"]}
              nav={{ isSidebar, setIsSidebar }}
            />
          }
        />
        <Route
          path="/appointment-dashboard"
          element={
            <PrivateRoute
              element={AppointmentDashboard}
              roles={["admin", "inhouse_installer"]}
              nav={{ isSidebar, setIsSidebar }}
            />
          }
        />


        {/* Client Management Routes */}
        <Route path="/list-client" element={<ClientList />} />
        <Route path="/add-client" element={<AddClient />} />
        {/* Dealer Management */}
        <Route
          path="/dealer/add-dealer"
          element={
            <PrivateRoute
              element={CreateDealer}
              roles={["admin"]}
              nav={navProps}
            />
          }
        />
        <Route
          path="/dealer/list-dealer"
          element={
            <PrivateRoute
              element={ListDealer}
              roles={["admin"]}
              nav={navProps}
            />
          }
        />
        <Route
          path="/dealer/edit-dealer/:id"
          element={
            <PrivateRoute
              element={EditDealer}
              roles={["admin"]}
              nav={navProps}
            />
          }
        />
        <Route
          path="/dealer/view-dealer/:id"
          element={
            <PrivateRoute
              element={ViewDealer}
              roles={["admin"]}
              nav={navProps}
            />
          }
        />
        <Route
          path="/dealer/account-view/:id"
          element={
            <PrivateRoute
              element={AccountView}
              roles={["admin"]}
              nav={navProps}
            />
          }
        />
        <Route
          path="/dealer/edit-profile/:id"
          element={
            <PrivateRoute element={Profile} roles={["admin"]} nav={navProps} />
          }
        />
         <Route
          path="/dealer/transactions/:id"
          element={
            <PrivateRoute element={TransactionHistory} roles={["admin"]} nav={navProps} />
          }
        />
        {/* Order management System */}
        <Route
          path="/order/list"
          element={
            <PrivateRoute
              element={ListOrder}
              roles={["admin", "production_manager", "production_associate"]}
              nav={{ isSidebar, setIsSidebar }}
            />
          }
        />
        <Route
          path="/order/add-order"
          element={
            <PrivateRoute
              element={AddOrder}
              roles={["admin", "production_manager"]}
              nav={{ isSidebar, setIsSidebar }}
            />
          }
        />
        <Route
          path="/order/order-detail/:id"
          element={
            <PrivateRoute
              element={DetailOrder}
              roles={["admin", "production_manager", "production_associate"]}
              nav={{ isSidebar, setIsSidebar }}
            />
          }
        />
        {/* User management System */}
        <Route
          path="/user/add-user"
          element={
            <PrivateRoute
              element={CreateUser}
              roles={["admin"]}
              nav={navProps}
            />
          }
        />
        <Route
          path="/user/manage-user"
          element={
            <PrivateRoute
              element={ManageUser}
              roles={["admin"]}
              nav={navProps}
            />
          }
        />
         <Route
          path="/user/edit-user/:id"
          element={
            <PrivateRoute
              element={EditUser}
              roles={["admin"]}
              nav={navProps}
            />
          }
        />
       
        {/* Analytics */}
        <Route
          path="/analytics/annual-sales"
          element={
            <PrivateRoute
              element={SalesAnalytics}
              roles={["admin"]}
              nav={navProps}
            />
          }
        />
        <Route
          path="/analytics/productions"
          element={
            <PrivateRoute
              element={ProductionAnalytics}
              roles={["admin"]}
              nav={navProps}
            />
          }
        />
        {/* Inventory Management */}
        <Route
          path="/inventory/add-item"
          element={
            <PrivateRoute
              element={AddInventoryItem}
              roles={["admin"]}
              nav={navProps}
            />
          }
        />
        <Route
          path="/inventory/view-item/:id"
          element={
            <PrivateRoute
              element={ViewInventoryItem}
              roles={["admin"]}
              nav={navProps}
            />
          }
        />
        <Route
          path="/inventory/inventory-list"
          element={
            <PrivateRoute
              element={ListInventory}
              roles={["admin"]}
              nav={navProps}
            />
          }
        />
        {/* Product Management */}
        <Route
          path="/product-management/add-product-attributes"
          element={
            <PrivateRoute
              element={AddProductAttribute}
              roles={["admin"]}
              nav={navProps}
            />
          }
        />
        {/* Applications */}
        <Route
          path="/app/invoice-generator"
          element={
            <PrivateRoute
              element={InvoiceApp}
              roles={["admin"]}
              nav={navProps}
            />
          }
        />
        //------------------------ PUBLIC ROUTES ------------------
        <Route path="/public/order" element={<OrderPage />} />
        {/* error 404 route */}
        <Route path="*" element={<Error404 />} />

      </Routes>
    </BrowserRouter>
  );
};

export default App;
