import React, { useEffect } from 'react'
import Header from '../../components/layout/Header'
import Sidebar from '../../components/layout/Sidebar'
import Detail from '../../components/appointment/Detail'
import { useNavigate, useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { AppointDetailAction } from '../../redux/actions/AppointActions'
import Loader from '../../components/layout/Loader'

const DetailAppointment = ({ nav }) => {
    
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { id } = useParams()

    const userLogin = useSelector(state => state.userSignIn)
    const { error, userInfo } = userLogin
    const detail = useSelector(state => state.appointDetail)
    const { loading, appoint, success } = detail

    useEffect(() => {
        if (!userInfo) {
            navigate('/sign-in')
        }
        window.scrollTo(0, 0)
    }, [userInfo, navigate])

    useEffect(() => {
        dispatch(AppointDetailAction(id,userInfo.token))
        window.scrollTo(0, 0)
    }, [dispatch, id])

    return (
        <>
            <div className={nav.isSidebar}>
                <Header nav={nav} />
                <Sidebar nav={nav} />
                {success ? (<Detail p={appoint} />) : (<Loader />)}
            </div>
        </>
    )
}

export default DetailAppointment