import React, { useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { API_URI } from "../../../constants/GlobalConstants";
import Loader from "../../layout/Loader";
import { useSelector } from "react-redux";

function EditPayment({ selected }) {
  const userLogin = useSelector((state) => state.userSignIn);
  const {  userInfo } = userLogin;
  const [updatedPayment, setUpdatedPayment] = useState(0);
  const [loading, setLoading] = useState(false);


  const submitUpdatePayment = async () => {
    setLoading(true);
    const val = {
      selected: selected[0],
      updatedPayment,
    };

    try {
      const { data } = await axios.put(
        `${API_URI}/api/order/update-payment`,
        val,
        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`
          }
        }
      );
      if (data) {
        console.log(data);
        setLoading(false);
        window.location.reload();
      }
    } catch (error) {
      alert("😭 Oop! Internal Error, contact the Admin🤓");
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="card">
          <div className="card-body">
            <div className="row mb-4">
              <div className="col-lg-8">
                <label className="form-label">Edit Payment</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Update payment"
                  value={updatedPayment}
                  onChange={(e) => setUpdatedPayment(e.target.value)}
                />
              </div>
              <div className="col-lg-4 mt-4">
                <div className="form-group">
                  <button
                    className="btn btn-success w-100"
                    type="submit"
                    onClick={submitUpdatePayment}
                  >
                    Submit the updated payment
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default EditPayment;
EditPayment.propTypes = {
  selected: PropTypes.array.isRequired,
};
