import { FETCH_CHART_DATA_FAILURE, FETCH_CHART_DATA_REQUEST, FETCH_CHART_DATA_SUCCESS, FETCH_DASHBOARD_DATA_FAILURE, FETCH_DASHBOARD_DATA_REQUEST, FETCH_DASHBOARD_DATA_SUCCESS } from "../../constants/DashboardConstants";

const dashboarddata = {
    loading: false,
    data: null,
    error: null,
  };
  
  export const dashboardReducer = (state = dashboarddata, action) => {
    switch (action.type) {
      case FETCH_DASHBOARD_DATA_REQUEST:
        return { ...state, loading: true };
      case FETCH_DASHBOARD_DATA_SUCCESS:
        return { ...state, loading: false, data: action.payload };
      case FETCH_DASHBOARD_DATA_FAILURE:
        return { ...state, loading: false, error: action.payload };
      default:
        return state;
    }
  };
  

  const chartstate = {
  loading: false,
  data: [],
  error: null,
};

export const chartReducer = (state = chartstate, action) => {
  switch (action.type) {
    case FETCH_CHART_DATA_REQUEST:
      return { ...state, loading: true };
    case FETCH_CHART_DATA_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case FETCH_CHART_DATA_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

