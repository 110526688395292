import React from 'react';
import { useNavigate } from 'react-router-dom';

const Error404 = () => {
  const navigate = useNavigate();

  return (
    <div
      className="error-404 d-flex align-items-center justify-content-center"
      style={{
        height: '100vh', // Full viewport height
        textAlign: 'center', // Center text
      }}
    >
      <div className="container">
        <div className="card py-5">
          <div className="row g-0">
            <div className="col col-xl-5">
              <div className="card-body p-4">
                <h1 className="display-1">
                  <span className="text-danger">4</span>
                  <span className="text-primary">0</span>
                  <span className="text-success">4</span>
                </h1>
                <h2 className="font-weight-bold display-4">Lost in Space</h2>
                <p>
                  You have reached the edge of the universe.
                  <br />
                  The page you requested could not be found.
                  <br />
                  Don't worry and return to the previous page.
                </p>
                <div className="mt-5">
                  <button
                    className="btn btn-primary btn-lg px-md-5 radius-30"
                    onClick={() => navigate('/')}
                  >
                    Go Home
                  </button>
                </div>
              </div>
            </div>
            <div className="col-xl-7 d-flex align-items-center justify-content-center">
              <img
                src="assets/images/error/404-error.png"
                className="img-fluid"
                alt="404 Error"
                style={{ maxWidth: '100%', maxHeight: '100%' }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error404;
