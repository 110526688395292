import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { listOrderAction } from '../../../redux/actions/OrderAction';
import Loader from '../../layout/Loader';

const RecentOrders = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userSignIn);
  const { userInfo } = userLogin;
  const orderList = useSelector((state) => state.orderList);
  const { loading, orders } = orderList;

  const handleViewMore = () => {
    navigate('/order/list');
  };

  useEffect(() => {
    dispatch(
      listOrderAction(
        { status: 'All', page: 1, limit: 6, orderBy: 'asc', order: 'asc' },
        userInfo.token
      )
    );
  }, [dispatch, userInfo.token]);

  return (
    <div className="card radius-10">
      <div className="card-header bg-transparent">
        <div className="row g-3 align-items-center">
          <div className="col">
            <h5 className="mb-0">Recent Orders</h5>
          </div>
          <div className="col">
            <button
              className="btn btn-primary float-end"
              onClick={handleViewMore}
            >
              View More
            </button>
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="table-responsive">
          {loading ? (
            <Loader />
          ) : (
            <table className="table align-middle mb-0">
              <thead className="table-light">
                <tr>
                  {/* <th>#ID</th> */}
                  <th>Dealer Name</th>
                  <th>Product Type</th>
                  <th>Quantity</th>
                  <th>Amount</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {orders?.orders?.map((order) => (
                  <tr key={order._id}>
                    {/* <td>{order._id}</td> */}
                    <td>{order.dealerName}</td>
                    <td>{order.productType}</td>
                    <td>{order.orderArray?.length}</td>
                    <td>${order.payment}</td>
                    <td>{new Date(order.createdAt).toLocaleDateString()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default RecentOrders;
