import { getDecimal, getFinalVal, nearestGreaterVal, totalDecimal } from "../GolbalFunctions"
import { heightArray, widthArray, wMatrix } from "./RollerMatrix"

export function rollerEquation(w, h, headrail) {
    const wFraction = totalDecimal(w)
    const hFraction = totalDecimal(h)
    var xFascia = 0
    var xTube = 0
    var xFabric = 0
    switch (headrail) {
        case 'DBS-Headrail':
            //Fascia cut = 3/8
            xFascia = wFraction - .375
            //Tube = 7/8
            xTube = wFraction - .875
            //Fabric cut = 1 1/8
            xFabric = wFraction - 1.125
            break;
        case 'Progressive':
            //Fascia cut = 3/8
            xFascia = wFraction - .375
            //Tube = 1
            xTube = wFraction - 1
            //Fabric cut = 1 1/8
            xFabric = wFraction - 1.125
            break;
        case 'Brite-Blinds':
            //Fascia cut = 3/8
            xFascia = wFraction - .375
            xTube = wFraction - 1.125
            xFabric = wFraction - 1.250
            break;
        case 'Prog-Cordless':
            //Fascia cut = 3/8
            xFascia = wFraction - .375
            xTube = wFraction - 0.625
            xFabric = wFraction - 0.750
            break;
        case 'Surrey-Open-Roll':
            xFascia = 0
            xTube = wFraction - 1.250
            xFabric = wFraction - 1.500
            break
        case 'Surrey-Open-Roll-Cordless':
            xFascia = 0
            xTube = wFraction - .875
            xFabric = wFraction - 1
            break

        default:
            //Fascia cut = 3/8
            xFascia = wFraction - .375
            //Tube = 7/8
            xTube = wFraction - .875
            //Fabric cut = 1 1/8
            xFabric = wFraction - 1.125
    }


    //Getting the sqft
    const wNearest = nearestGreaterVal(widthArray, wFraction)
    const hNearest = nearestGreaterVal(widthArray, hFraction)
    const sqft = wMatrix[widthArray.indexOf(wNearest)][heightArray.indexOf(hNearest)]
    return ({
        fascia: getFinalVal(xFascia),
        tube: getFinalVal(xTube),
        fabric: getFinalVal(xFabric),
        sqft
    })
}
