import { TRANSACTION_LIST_FAIL, TRANSACTION_LIST_REQUEST, TRANSACTION_LIST_SUCCESS } from "../../constants/TransactionContants";

  
  const initialState = {
    transactions: [],
    loading: false,
    error: null,
    totalPages: 1,
  };
  
  export const transactionListReducer = (state = initialState, action) => {
    switch (action.type) {
      case TRANSACTION_LIST_REQUEST:
        return { ...state, loading: true };
      case TRANSACTION_LIST_SUCCESS:
        return {
          ...state,
          loading: false,
          transactions: action.payload.transactions,
          totalPages: action.payload.pages,
        };
      case TRANSACTION_LIST_FAIL:
        return { ...state, loading: false, error: action.payload };
      default:
        return state;
    }
  };
  