import { IconButton, Tooltip } from "@mui/material";
import { productsArr } from "../../constants/GlobalConstants";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"; // Import the HelpOutlineIcon
import "./styles.css";

const ProDependency = ({ proDep, setProDep }) => {
  function handleKeyDown(e) {
    // If user did not press enter key, return
    if (e.key !== ",") return;
    // Get the value of the input
    const value = e.target.value;
    // If the value is empty, return
    if (!value.trim()) return;
    // Add the value to the tags array
    setProDep([...proDep, value]);
    // Clear the input
    e.target.value = "";
  }

  function removeTag(index) {
    setProDep(proDep.filter((el, i) => i !== index));
  }

  function resetTag(e) {
    e.preventDefault();
    setProDep(productsArr);
  }

  return (
    <>
      <label className="form-label test">
        {" "}
        Product Dependency
        <Tooltip
          title={
            <span style={{ fontSize: ".8rem" }}>
              Indicate which products are dependent on this item. For example,
              if your tube is used exclusively in roller shades, select "roller
              shades" as its <span className="text-info">dependency </span>{" "}
            </span>
          }
          arrow
        >
          <IconButton size="small" sx={{ color: "#fff" }}>
            <HelpOutlineIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
      </label>
      <div className="tags-input-container">
        {proDep.map((tag, index) => (
          <div className="tag-item" key={index}>
            <span className="text text-black">{tag}</span>
            <span className="close" onClick={() => removeTag(index)}>
              &times;
            </span>
          </div>
        ))}
        <button
          className="btn btn-sm btn-warning px-5 radius-30"
          onClick={resetTag}
        >
          Reset Product Dependency
        </button>
        {/* <input onKeyDown={handleKeyDown} type="text" className="tags-input" placeholder="Type somthing" /> */}
      </div>
    </>
  );
};

export default ProDependency;
