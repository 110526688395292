import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { API_URI } from "../../constants/GlobalConstants";
import { Alert, IconButton, InputAdornment, Snackbar, TextField } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { updateUser } from "../../redux/actions/userManageAction";

const EditUser = () => {
  const { id } = useParams(); 
  const navigate = useNavigate();
  const userLogin = useSelector((state) => state.userSignIn);
  const { userInfo } = userLogin;
  const dispatch=useDispatch()

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("inhouse_installer");

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertType, setAlertType] = useState("success"); 
  const [alertMessage, setAlertMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (!userInfo) {
      navigate("/sign-in");
      return;
    }

    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          `${API_URI}/api/user-manage/user/${id}`,
          {
            headers: {
              Authorization: `Bearer ${userInfo.token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const user = response.data;
        setUsername(user.username);
        setEmail(user.email);
        setRole(user.roles[0] || "inhouse_installer");
      } catch (error) {
        setAlertType("error");
        setAlertMessage(
          "Error fetching user data: " +
            (error.response?.data?.message || error.message)
        );
        setAlertOpen(true);
      }
    };

    fetchUserData();
    window.scrollTo(0, 0);
  }, [userInfo, id, navigate]);

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const updateData = { username, email, roles: [role] };

      if (password) {
        updateData.password = password;
      }

      await dispatch(updateUser(id, updateData, userInfo.token));
      
      setAlertType("success");
      setAlertMessage("User updated successfully!");
      // dispatch(fetchUserList(userInfo.token));
      navigate("/user/manage-user");
    } catch (error) {
      setAlertType("error");
      setAlertMessage(
        "Error updating user: " +
          (error.response?.data?.message || error.message)
      );
    }
    setAlertOpen(true);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <div className="row">
        <form onSubmit={handleSubmit}>
          <div className="col-lg-12 mx-auto">
            <div className="card">
              <div className="card-body">
                <h5>User Info</h5>
                <div className="row g-3">
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="form-group">
                      <label className="form-label">Username</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Username"
                        required
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="form-group">
                      <label className="form-label">Email</label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="form-group position-relative">
                      <label className="form-label">Password (leave blank to keep current)</label>
                      <input
                        type={showPassword ? "text" : "password"}
                        className="form-control"
                        placeholder="New Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <IconButton
                        onClick={handleClickShowPassword}
                        edge="end"
                        className="position-absolute"
                        style={{ right: 14, top: "70%", transform: "translateY(-50%)",color:"white" }}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="form-group">
                      <label className="form-label">Role</label>
                      <select
                        className="form-control"
                        value={role}
                        onChange={(e) => setRole(e.target.value)}
                      >
                        <option value="admin">Admin</option>
                        <option value="production_manager">
                          Production Manager
                        </option>
                        <option value="production_associate">
                          Production Associate
                        </option>
                        <option value="inhouse_installer">
                          Inhouse Installer
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-header py-3 bg-transparent">
                <div className="d-sm-flex align-items-center">
                  <h5 className="mb-2 mb-sm-0">Edit User</h5>
                  <div className="ms-auto">
                    <button type="submit" className="btn btn-primary">
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      {/* MUI Snackbar for alerts */}
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity={alertType}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default EditUser;
