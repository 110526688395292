export const DEALER_CREATE_REQUEST = 'DEALER_CREATE_REQUEST'
export const DEALER_CREATE_SUCCESS = 'DEALER_CREATE_SUCCESS'
export const DEALER_CREATE_FAIL = 'DEALER_CREATE_FAIL'

export const DEALER_LIST_REQUEST = 'DEALER_LIST_REQUEST'
export const DEALER_LIST_SUCCESS = 'DEALER_LIST_SUCCESS'
export const DEALER_LIST_FAIL = 'DEALER_LIST_FAIL'

export const DEALER_UPDATE_REQUEST = 'DEALER_UPDATE_REQUEST'
export const DEALER_UPDATE_SUCCESS = 'DEALER_UPDATE_SUCCESS'
export const DEALER_UPDATE_FAIL = 'DEALER_UPDATE_FAIL'

export const GET_DEALER_ORDER_REQUEST = "GET_DEALER_ORDER_REQUEST";
export const GET_DEALER_ORDER_SUCCESS = "GET_DEALER_ORDER_SUCCESS";
export const GET_DEALER_ORDER_FAILURE = "GET_DEALER_ORDER_FAILURE";

export const GET_BALANCE_REQUEST = "GET_BALANCE_REQUEST";
export const GET_BALANCE_SUCCESS = "GET_BALANCE_SUCCESS";
export const GET_BALANCE_FAILURE = "GET_BALANCE_FAILURE";
