import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Overview from '../components/analytics/cards/Overview'
import Header from '../components/layout/Header'
import Loader from '../components/layout/Loader'
import LoaderLin from '../components/layout/LoaderLin'
import Sidebar from '../components/layout/Sidebar'
import AppointmentsTable from '../components/tables/AppointmentsTable'
import { ListAppointAction, ListCompletedTaskAction, listGeneralInquiriesAction, ListOrderAction, ListPaymentPendingAction, listQuotationAction } from '../redux/actions/AppointActions'
import DashboardCharts from '../components/analytics/cards/HomeOverview'
import PieChartWithLegend from '../components/analytics/Charts/PieChartWithLegends'
import SalesAnalyticsContent from '../components/analytics/Charts/AnnualSalesGraph'
import RecentOrders from '../components/analytics/Charts/DashboardOrderTable'

const Home = ({ nav }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const userLogin = useSelector(state => state.userSignIn)
    const { error, userInfo } = userLogin

    const listAppointment = useSelector(state => state.listAppointment)
    const { loading, appoints, success } = listAppointment

    const listOrder = useSelector(state => state.listOrder)
    const { loading: loadingOrders, orders, success: orderSuccess } = listOrder

    const completedTask = useSelector(state => state.listCompletedTask)
    const { loading: loadingTask, task, success: taskSuccess } = completedTask

    const paymentPending = useSelector(state => state.ListPaymentPending)
    const { loading: LoadingPending, orders: pOrders, success: pPendingSuccess } = paymentPending

    const actionList = useSelector(state => state.ListQuotation)
    const { loading: loadingQuatation, actions } = actionList
    const inquiresList = useSelector(state => state.ListInquiries)
    const { loading: LoadingInquiries, actions: inquiries } = inquiresList

    const counter = () => {
        const appointCount = Object.keys(appoints).length
        const productionCount = Object.keys(orders).length
        const paymentPendingCount = Object.keys(pOrders).length
        const pPending = pOrders.reduce((acc, obj) => {
            return acc + obj.payment
        }, 0)
        const counts = {
            appoints: appointCount,
            production: productionCount,
            pCount: paymentPendingCount,
            pPending
        }
        return counts
    }

    useEffect(() => {
        if (!userInfo) {
            navigate('/sign-in')
        } else {
            const token=userInfo.token
            dispatch(ListAppointAction(token))
            dispatch(ListOrderAction(token))
            dispatch(ListCompletedTaskAction(token))
            dispatch(ListPaymentPendingAction(token))
            dispatch(listQuotationAction(token))
            dispatch(listGeneralInquiriesAction(token))
        }
    }, [userInfo, navigate,dispatch])

    useEffect(()=>{
        if (!userInfo) {
            navigate('/sign-in');
        } else if (userInfo.roles.includes('admin') || userInfo.isAdmin) {
            navigate('/');
        } else if (userInfo.roles.includes('production_manager') || userInfo.roles.includes('production_associate')) {
            navigate('/order/list');
        } else if (userInfo.roles.includes('inhouse_installer')) {
            navigate('/appointment-dashboard');
        }

    },[userInfo,navigate])

    return (
        <>

            {userInfo && (
                <div className={nav.isSidebar}>
                    <Header nav={nav} />
                    <Sidebar nav={nav} />
                    {/*start content*/}
                    <main className="page-content">
                        <DashboardCharts />
                        <div className="row mb-3">
                            <div className="col-md-6  mb-3 mb-md-0">
                                <PieChartWithLegend />
                            </div>
                            <div className="col-md-6  mb-3 mb-md-0">
                                <SalesAnalyticsContent />
                            </div>
                        </div>
                            <RecentOrders />

                      
                    </main>
                    <div className="overlay nav-toggle-icon" />

                    <a href="" className="back-to-top">
                        <i className="bx bxs-up-arrow-alt" />
                    </a>

                </div>
            )}

        </>
    )
}

export default Home