import { FETCH_ITEMS_FAILURE, FETCH_ITEMS_REQUEST, FETCH_ITEMS_SUCCESS } from "../../constants/InventoryConstants";

export const createInvItemReducer = (state = {}, action) => {
    switch (action.type) {
        case 'INV_ITEM_CREATE_REQUEST':
            return { loading: true }
        case 'INV_ITEM_CREATE_SUCCESS':
            return { loading: false, success: true, data: action.payload }
        case 'INV_ITEM_CREATE_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state;
    }
}

export const getInvItemsByCatReducer = (state = {}, action) => {
    switch (action.type) {
        case 'INV_ITEMS_GET_REQUEST':
            return { loading: true }
        case 'INV_ITEMS_GET_SUCCESS':
            return { loading: false, success: true, data: action.payload }
        case 'INV_ITEMS_GET_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state;
    }
}

const inventorytablestate = {
    data: [],
    loading: false,
    error: null
};

const getInventoryTableData = (state = inventorytablestate, action) => {
    switch (action.type) {
        case FETCH_ITEMS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case FETCH_ITEMS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload
            };
        case FETCH_ITEMS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
};

export default getInventoryTableData;