import { nanoid } from 'nanoid';
import React, { useEffect } from 'react'
import { useState } from 'react'
import Badge from '../layout/Badge';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import Loader from '../layout/Loader';
import fileDownload from 'js-file-download'
import { createDealerOrderAction } from '../../redux/actions/OrderAction'
import { ListDealerAction } from '../../redux/actions/DealerAction';
import { getProductionData, updateOrderArr } from './functions/OrderFunctions';
//MUI Dropdown Action
import ContentCutIcon from '@mui/icons-material/ContentCut';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import AddIcon from '@mui/icons-material/Add';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import { MUIDropdown } from '../ui/materialUI/elements';
import { Container, MenuItem } from '@mui/material';
import { enableSplit, SplitInput } from './OrderComp/attributes/SplitAttribute';
import { DeductionInput } from './OrderComp/attributes/Deduction';
import { AdditionInput } from './OrderComp/attributes/Addition';
import { ProductInsertion } from './OrderComp/attributes/Insertion';
import { NotificationBadge } from './ui/NotificationBadge';
import OrderOverviewUI from './OrderComp/tables/OrderOverviewUI';
import { OrderTableHeader, OrderTableValues } from './OrderComp/tables/OrderTableUI';
import { toggleFullScreen } from '../layout/functions/layout';

const OrderCreate = ({ nav }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const userLogin = useSelector(state => state.userSignIn)
    const { error, userInfo } = userLogin
    const listDealer = useSelector(state => state.listDealer)
    const { loading: loadingClient, dealer, success } = listDealer
    const createOrder = useSelector(state => state.orderDealerCreate)
    const { loading: loadingCreateOrder, data, success: successOrder } = createOrder

    const [errToggle, setErrToggle] = useState({});
    const [dropdownToggle, setDropdownToggle] = useState(null);

    const [dealerName, setDealerName] = useState('');
    const [productTag, setProductTag] = useState('');
    const [productType, setProductType] = useState('');
    const [productMaterial, setProductMaterial] = useState('');
    const [headrail, setHeadrail] = useState('');
    const [liftStyle, setLiftStyle] = useState('');
    const [orderRemark, setOrderRemark] = useState('');

    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [control, setControl] = useState('right');
    const [mount, setMount] = useState('inside');
    const [lift, setLift] = useState('corded');
    const [remark, setRemark] = useState('');
    const [location, setLocation] = useState('');
    const [order, setOrder] = useState([]);
    const [orderFactor, setOrderFactor] = useState(0);
    const [customCost, setCustomCost] = useState(0);
    //Product Attributes
    const [splitWidth, setSplitWidth] = useState({ r: 0, l: 0, m: 0, trigger: false });
    const [deduction, setDeduction] = useState({ val: null, trigger: false });
    const [addition, setAddition] = useState({ val: null, trigger: false });
    const [insertion, setInsertion] = useState({ val: null, trigger: false });

    const [editOrderId, setEditOrderId] = useState(null);
    const [widthEdit, setWidthEdit] = useState(0);
    const [heightEdit, setHeightEdit] = useState(0);
    const [controlEdit, setControlEdit] = useState('right');
    const [liftEdit, setLiftEdit] = useState('corded');
    const [mountEdit, setMountEdit] = useState('inside');
    const [remarkEdit, setRemarkEdit] = useState('');
    const [editLocation, setEditLocation] = useState('');
    const [editFormData, setEditFormData] = useState({
        width: 0,
        height: 0,
        control: '',
        lift: '',
        mount: '',
        remark: ''
    });

    const handleAddDataSubmit = (e) => {
        e.preventDefault()
        if (!productType) {
            setErrToggle({ type: 'danger', message: 'Please select the product type' })
        } else {
            setErrToggle({})
            //Geting the processed product data
            const processedOrder = getProductionData(productType, width, height, control, lift, mount, remark, location, headrail, deduction.val, addition.val)
            if (insertion.val > 0 && insertion.trigger) {
                order.splice(insertion.val - 1, 0, processedOrder)
                console.log(insertion.val)
            } else {
                setOrder([...order, processedOrder])
            }
        }
    }

    //_ Editing the object inside the orderArray
    const handleEditClick = (e, editOrder) => {
        setEditOrderId(editOrder.id)
        setWidthEdit(editOrder.width)
        setHeightEdit(editOrder.height)
        setControlEdit(editOrder.control)
        setLiftEdit(editOrder.lift)
        setMountEdit(editOrder.mount)
        setRemarkEdit(editOrder.remark)
        setEditLocation(editOrder.location)
        setEditFormData({ ...editOrder })
    }

    const handleEditDataSubmit = () => {
        setOrder(updateOrderArr({
            OrderArr: order, editProductId: editOrderId,
            productType,
            widthEdit, heightEdit, remarkEdit, editLocation, headrail
        }))
        setEditOrderId(null)

    }

    //+ Cancel the data object
    const handleCancelData = () => {
        setEditOrderId(null)
    }

    //= Delete the order obj from Order Array
    const handleDeleteData = (orderId) => {
        const newOrder = [...order]
        const index = order.findIndex((o) => o.id === orderId)
        newOrder.splice(index, 1);
        setOrder(newOrder)
    }

    //----- Submitting the order to the DB → 💽
    const handleSubmitOrder = async () => {
        let dealer = dealerName.split('_')
        const val = {
            dealerName: dealer[0], dealerId: dealer[1], productTag, productType,
            productMaterial, liftStyle, orderRemark, order, orderFactor, customCost, headrail
        }
        await dispatch(createDealerOrderAction(val, userInfo.token))
    }


    useEffect(() => {
        if (!userInfo) {
            navigate('/sign-in')
        }
        window.scrollTo(0, 0)
        toggleFullScreen(nav = { nav })
    }, [userInfo, navigate])

    useEffect(() => {
        window.scrollTo(0, document.body.scrollHeight)
        setOrderFactor(order.reduce((n, { factor }) => n + factor, 0))
    }, [order]);

    useEffect(() => {
        dispatch(ListDealerAction(userInfo.token))
    }, []);

    useEffect(() => {
        let dealer = dealerName.split('_')
        if (data) {
            fileDownload(data, `${dealer[0]}-${productType}-${productTag}.pdf`)
        }
        // dispatch({ type: 'DEALER_ORDER_CREATE_RESET' })
    }, [data])

    return (
        <>
            {loadingCreateOrder ? (<Loader />) : (
                <>
                    {successOrder ? (<Badge p={{
                        type: 'success',
                        message: 'New Order has been made 🙂',
                        redirectName: 'Click here to submit another order',
                        redirect: '/order/add-order',
                        refresh: true
                    }} />) : (
                        <>
                            <div className="row mt-5 mb-4">
                                <div className="row">
                                    <div className="col-lg-10">
                                        <h6 className="mb-2 text-uppercase">Order Info</h6>
                                    </div>
                                    <div className="col-lg-2">
                                        <div className="form-group">
                                            {dealerName && productType ? (<button className="btn btn-success w-100"
                                                type="submit" onClick={handleSubmitOrder}
                                            >
                                                Submit the Order
                                            </button>) : (<button className="btn btn-light w-100"
                                                type="submit" disabled
                                            >
                                                Submit the Order
                                            </button>)}
                                        </div>
                                    </div>
                                </div>
                                {!loadingClient ? (
                                    <>
                                        <div className="col-lg-8 row g-3">
                                            <div className="col-lg-4">
                                                <label className="form-label"> Company Name</label>
                                                <select className="form-select"
                                                    value={dealerName} onChange={(e) => setDealerName(e.target.value)}>
                                                    <option value=''></option>
                                                    {dealer && dealer.map((c) => (
                                                        <option value={c.name + '_' + c._id}>{`${c.companyName}-${c.name}`}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-lg-4">
                                                <label className="form-label"> Product Tag </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Order PO/Tag"
                                                    value={productTag} onChange={(e) => setProductTag(e.target.value)}
                                                />
                                            </div>
                                            <div className="col-lg-4">
                                                <label className="form-label"> Lift Style </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Lift Style"
                                                    value={liftStyle} onChange={(e) => setLiftStyle(e.target.value)}
                                                />
                                            </div>
                                            <div className="col-lg-4">
                                                <label className="form-label"> Product Type</label>
                                                <select className="form-select"
                                                    value={productType} onChange={(e) => setProductType(e.target.value)}>
                                                    {!productType ? (
                                                        <>
                                                            <option></option>
                                                            <option value='Fauxwood'>2" Faux wood</option>
                                                            <option value='Zebra_blinds'>Zebra Blinds</option>
                                                            <option value='Roller_shades'>Roller Shades</option>
                                                            <option value='Vertical_blinds'>Vertical Blinds</option>
                                                            <option value='Materials'>Materials/Components</option>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <option>{productType}</option>
                                                            {/* <option value=''>--Reset*--</option> */}
                                                        </>
                                                    )
                                                    }
                                                </select>
                                            </div>
                                            <div className="col-lg-4">
                                                <label className="form-label">Headrail/Cassette</label>
                                                <select className="form-select" required
                                                    value={headrail} onChange={(e) => setHeadrail(e.target.value)}>
                                                    {!headrail ? (
                                                        <>
                                                            {(() => {
                                                                switch (productType) {
                                                                    case 'Zebra_blinds':
                                                                        return (
                                                                            <>
                                                                                <option></option>
                                                                                <option value='DBS-Headrail'>DBS-Headrail</option>
                                                                                <option value='Progressive'>Progressive</option>
                                                                                <option value='Brite-Blinds'>Brite Blinds</option>
                                                                                <option value='Prog-Cordless'>Prog Cordless</option>
                                                                            </>
                                                                        )
                                                                    case 'Roller_shades':
                                                                        return (
                                                                            <>
                                                                                <option></option>
                                                                                <option value='DBS-Headrail'>DBS-Headrail</option>
                                                                                <option value='Surrey-Open-Roll'>Surrey Open Roll</option>
                                                                                <option value='Surrey-Open-Roll-Cordless'>Surrey Open Roll Cordless</option>
                                                                                <option value='Progressive'>Progressive</option>
                                                                                <option value='Brite-Blinds'>Brite Blinds</option>
                                                                                <option value='Prog-Cordless'>Prog Cordless</option>
                                                                            </>
                                                                        )
                                                                    case 'Fauxwood':
                                                                        return (
                                                                            <>
                                                                                <option></option>
                                                                                <option value='DBS-Headrail'>DBS-Headrail</option>
                                                                            </>
                                                                        )
                                                                    case 'Vertical_blinds':
                                                                        return (
                                                                            <>
                                                                                <option></option>
                                                                                <option value='DBS-Headrail'>DBS-Headrail</option>
                                                                            </>
                                                                        )
                                                                    case 'Materials':
                                                                        return (
                                                                            <>
                                                                                <option></option>

                                                                            </>
                                                                        )
                                                                    default:
                                                                        return null
                                                                }
                                                            })()}
                                                        </>
                                                    ) : (
                                                        <>
                                                            <option>{headrail}</option>
                                                            {/* <option value=''>--Reset*--</option> */}
                                                        </>
                                                    )
                                                    }
                                                </select>
                                            </div>
                                            <div className="col-lg-4">
                                                <label className="form-label"> Product Material</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Fabric/Material"
                                                    value={productMaterial}
                                                    onChange={(e) => setProductMaterial(e.target.value)}
                                                />
                                            </div>

                                        </div>
                                        <div className="col-lg-4 row g-3">
                                            <div className="col-12">
                                                <label className="form-label">Order Remark</label>
                                                <textarea
                                                    className="form-control"
                                                    placeholder=" Remarks, order tag..."
                                                    rows={5}
                                                    cols={4}
                                                    defaultValue={""}
                                                    value={orderRemark}
                                                    onChange={(e) => setOrderRemark(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <Loader />
                                )}

                            </div>

                            {errToggle.type && <Badge p={errToggle} />}
                            <hr />
                            <h6 className="mb-0 text-uppercase">Order Overview </h6>
                            <OrderOverviewUI
                                productType={productType}
                                orderFactor={orderFactor}
                                productMaterial={productMaterial}
                            />

                            {/* Order Tables: View,edit and delete function for invidual product */}
                            <h6 className="mb-0 text-uppercase">Order Data Table </h6>
                            <hr />
                            <div className="card">
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table
                                            id="example"
                                            className="table table-striped table-bordered"
                                            style={{ width: "100%" }}
                                        >
                                            {(() => {
                                                switch (productType) {
                                                    case 'Zebra_blinds':
                                                    case 'Roller_shades':
                                                        return (
                                                            <>
                                                                <thead>
                                                                    <tr>
                                                                        <th>#</th>
                                                                        <th>Width</th>
                                                                        <th>Height</th>
                                                                        <th>Fascia</th>
                                                                        <th>Tube</th>
                                                                        <th>Fabric</th>
                                                                        <th>Remarks</th>
                                                                        <th>Location</th>
                                                                        <th>Action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {order.map((o, index) => (
                                                                        <>
                                                                            {editOrderId === o.id ? (
                                                                                // Edit mode on 
                                                                                <tr>
                                                                                    <td>{'index'}</td>
                                                                                    <td className="col-2">
                                                                                        <input type="text" className="form-control" placeholder="Width"
                                                                                            value={widthEdit} onChange={(e) => setWidthEdit(e.target.value)}
                                                                                        />
                                                                                    </td>
                                                                                    <td className="col-2">
                                                                                        <input type="text" className="form-control" placeholder="Height"
                                                                                            value={heightEdit} onChange={(e) => setHeightEdit(e.target.value)}
                                                                                        />
                                                                                    </td>
                                                                                    <td className="h5"></td>
                                                                                    <td className="h5"></td>
                                                                                    <td className="h5"></td>
                                                                                    <td className="col">
                                                                                        <input type="text" className="form-control" placeholder="control"
                                                                                            value={remarkEdit} onChange={(e) => setRemarkEdit(e.target.value)}
                                                                                        />
                                                                                    </td>
                                                                                    <td className="col">
                                                                                        <input type="text" className="form-control" placeholder="location"
                                                                                            value={editLocation} onChange={(e) => setEditLocation(e.target.value)}
                                                                                        />
                                                                                    </td>
                                                                                    <td className="col-1 mt-4 " >
                                                                                        <div >
                                                                                            <button type='submit'
                                                                                                onClick={handleEditDataSubmit}
                                                                                                className="mt-1 btn btn-primary  btn-sm px-3 w-100">
                                                                                                Add
                                                                                            </button>
                                                                                        </div>
                                                                                        <div >
                                                                                            <button type='submit'
                                                                                                onClick={handleCancelData}
                                                                                                className="mt-1 btn btn-danger  btn-sm w-100">
                                                                                                Cancel
                                                                                            </button>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            ) : (
                                                                                // Table view & action buttons | Edit mode off
                                                                                <tr>
                                                                                    <td >{index + 1}</td>
                                                                                    <td className="h5" >{o.width}</td>
                                                                                    <td className="h5">{o.height}</td>
                                                                                    <td className="h5">{o.fascia}</td>
                                                                                    <td className="h5">{o.tube}</td>
                                                                                    <td className="h5">{o.fabric}</td>
                                                                                    <td >{o.remark}</td>
                                                                                    <td >{o.location}</td>
                                                                                    <td >
                                                                                        <div className="table-actions d-flex align-items-center gap-3 fs-6">
                                                                                            <button
                                                                                                onClick={(e) => handleEditClick(e, o)}
                                                                                                className="text-warning bg-dark" data-bs-toggle="tooltip"
                                                                                                data-bs-placement="bottom" title="Edit">
                                                                                                <i className="bi bi-pencil-fill"></i>
                                                                                            </button>
                                                                                            <a href="javascript:;" className="text-primary" data-bs-toggle="tooltip"
                                                                                                data-bs-placement="bottom" title="Views"><i className="bi bi-eye-fill"></i>
                                                                                            </a>
                                                                                            <button
                                                                                                onClick={() => handleDeleteData(o.id)}
                                                                                                className="text-danger bg-dark" data-bs-toggle="tooltip"
                                                                                                data-bs-placement="bottom" title="Delete"><i className="bi bi-trash-fill"></i>
                                                                                            </button>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                            }
                                                                        </>

                                                                    ))}
                                                                </tbody>
                                                            </>
                                                        )
                                                    case 'Fauxwood':
                                                        return (
                                                            <>
                                                                <thead>
                                                                    <tr>
                                                                        <th>#</th>
                                                                        <th>Width</th>
                                                                        <th>Height</th>
                                                                        <th>R-Width</th>
                                                                        <th>R-Height</th>
                                                                        <th>Slat</th>
                                                                        <th>Remarks</th>
                                                                        <th>Location</th>
                                                                        <th>Action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {order.map((o, index) => (
                                                                        <>
                                                                            {
                                                                                editOrderId === o.id ? (
                                                                                    <tr>
                                                                                        <td>{'index'}</td>
                                                                                        <td className="col-2">
                                                                                            <input type="text" className="form-control" placeholder="Width"
                                                                                                value={widthEdit} onChange={(e) => setWidthEdit(e.target.value)}
                                                                                            />
                                                                                        </td>
                                                                                        <td className="col-2">
                                                                                            <input type="text" className="form-control" placeholder="Height"
                                                                                                value={heightEdit} onChange={(e) => setHeightEdit(e.target.value)}
                                                                                            />
                                                                                        </td>
                                                                                        <td className="h5"></td>
                                                                                        <td className="h5"></td>
                                                                                        <td className="h5"></td>
                                                                                        <td className="col">
                                                                                            <input type="text" className="form-control" placeholder="control"
                                                                                                value={remarkEdit} onChange={(e) => setRemarkEdit(e.target.value)}
                                                                                            />
                                                                                        </td>
                                                                                        <td className="col">
                                                                                            <input type="text" className="form-control" placeholder="location"
                                                                                                value={editLocation} onChange={(e) => setEditLocation(e.target.value)}
                                                                                            />
                                                                                        </td>
                                                                                        <td className="col-1 mt-4 " >
                                                                                            <div >
                                                                                                <button type='submit'
                                                                                                    onClick={handleEditDataSubmit}
                                                                                                    className="mt-1 btn btn-primary  btn-sm px-3 w-100">
                                                                                                    Add
                                                                                                </button>
                                                                                            </div>
                                                                                            <div >
                                                                                                <button type='submit'
                                                                                                    onClick={handleCancelData}
                                                                                                    className="mt-1 btn btn-danger  btn-sm w-100">
                                                                                                    Cancel
                                                                                                </button>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                ) : (
                                                                                    <tr>
                                                                                        <td>{index + 1}</td>
                                                                                        <td className="h5">{o.width}</td>
                                                                                        <td className="h5">{o.height}</td>
                                                                                        <td className="h5">{o.readyWidth}</td>
                                                                                        <td className="h5">{o.height}</td>
                                                                                        <td className="h5">{o.slat}</td>
                                                                                        <td >{o.remark}</td>
                                                                                        <td >{o.location}</td>
                                                                                        <td >
                                                                                            <div className="table-actions d-flex align-items-center gap-3 fs-6">
                                                                                                <button
                                                                                                    onClick={(e) => handleEditClick(e, o)}
                                                                                                    className="text-warning bg-dark" data-bs-toggle="tooltip"
                                                                                                    data-bs-placement="bottom" title="Edit">
                                                                                                    <i className="bi bi-pencil-fill"></i>
                                                                                                </button>
                                                                                                <a href="javascript:;" className="text-primary" data-bs-toggle="tooltip"
                                                                                                    data-bs-placement="bottom" title="Views"><i className="bi bi-eye-fill"></i>
                                                                                                </a>
                                                                                                <button
                                                                                                    onClick={() => handleDeleteData(o.id)}
                                                                                                    className="text-danger bg-dark" data-bs-toggle="tooltip"
                                                                                                    data-bs-placement="bottom" title="Delete"><i className="bi bi-trash-fill"></i>
                                                                                                </button>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                )

                                                                            }
                                                                        </>

                                                                    ))}
                                                                </tbody>
                                                            </>
                                                        )
                                                    case 'Vertical_blinds':
                                                        return (
                                                            <>
                                                                <thead>
                                                                    <tr>
                                                                        <th>#</th>
                                                                        <th>Width</th>
                                                                        <th>Height</th>
                                                                        <th>Chain</th>
                                                                        <th>Cord</th>
                                                                        <th>Track</th>
                                                                        <th>Vanes</th>
                                                                        <th>MD Vanes</th>
                                                                        <th>PVC</th>
                                                                        <th>Location</th>
                                                                        <th>Remark</th>
                                                                        <th>Action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {order.map((o, index) => (
                                                                        <>
                                                                            {
                                                                                editOrderId === o.id ? (
                                                                                    <tr>
                                                                                        <td>{'index'}</td>
                                                                                        <td className="col-2">
                                                                                            <input type="text" className="form-control" placeholder="Width"
                                                                                                value={widthEdit} onChange={(e) => setWidthEdit(e.target.value)}
                                                                                            />
                                                                                        </td>
                                                                                        <td className="col-2">
                                                                                            <input type="text" className="form-control" placeholder="Height"
                                                                                                value={heightEdit} onChange={(e) => setHeightEdit(e.target.value)}
                                                                                            />
                                                                                        </td>
                                                                                        <td className="h5"></td>
                                                                                        <td className="h5"></td>
                                                                                        <td className="h5"></td>
                                                                                        <td className="h5"></td>
                                                                                        <td className="h5"></td>
                                                                                        <td className="h5"></td>
                                                                                        <td className="col">
                                                                                            <input type="text" className="form-control" placeholder="control"
                                                                                                value={remarkEdit} onChange={(e) => setRemarkEdit(e.target.value)}
                                                                                            />
                                                                                        </td>
                                                                                        <td className="col">
                                                                                            <input type="text" className="form-control" placeholder="location"
                                                                                                value={editLocation} onChange={(e) => setEditLocation(e.target.value)}
                                                                                            />
                                                                                        </td>
                                                                                        <td className="col-1 mt-4 " >
                                                                                            <div >
                                                                                                <button type='submit'
                                                                                                    onClick={handleEditDataSubmit}
                                                                                                    className="mt-1 btn btn-primary  btn-sm px-3 w-100">
                                                                                                    Add
                                                                                                </button>
                                                                                            </div>
                                                                                            <div >
                                                                                                <button type='submit'
                                                                                                    onClick={handleCancelData}
                                                                                                    className="mt-1 btn btn-danger  btn-sm w-100">
                                                                                                    Cancel
                                                                                                </button>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                ) : (
                                                                                    <tr>
                                                                                        <td>{index + 1}</td>
                                                                                        <td className="h5">{o.width}</td>
                                                                                        <td className="h5">{o.height}</td>
                                                                                        <td className="h5">{o.chain}</td>
                                                                                        <td className="h5">{o.cord}</td>
                                                                                        <td className="h5">{o.track}</td>
                                                                                        <td className="h5">{o.vanes}</td>
                                                                                        <td className="h5">{o.vaneDed}</td>
                                                                                        <td className="h5">{o.pvc}</td>
                                                                                        <td >{o.remark}</td>
                                                                                        <td >{o.location}</td>
                                                                                        <td >
                                                                                            <div className="table-actions d-flex align-items-center gap-3 fs-6">
                                                                                                <button
                                                                                                    onClick={(e) => handleEditClick(e, o)}
                                                                                                    className="text-warning bg-dark" data-bs-toggle="tooltip"
                                                                                                    data-bs-placement="bottom" title="Edit">
                                                                                                    <i className="bi bi-pencil-fill"></i>
                                                                                                </button>
                                                                                                <a href="javascript:;" className="text-primary" data-bs-toggle="tooltip"
                                                                                                    data-bs-placement="bottom" title="Views"><i className="bi bi-eye-fill"></i>
                                                                                                </a>
                                                                                                <button
                                                                                                    onClick={() => handleDeleteData(o.id)}
                                                                                                    className="text-danger bg-dark" data-bs-toggle="tooltip"
                                                                                                    data-bs-placement="bottom" title="Delete"><i className="bi bi-trash-fill"></i>
                                                                                                </button>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                )

                                                                            }
                                                                        </>

                                                                    ))}
                                                                </tbody>
                                                            </>
                                                        )
                                                    case 'Materials':
                                                        return (
                                                            <thead>
                                                                <tr>
                                                                    <th className='col-lg-6 h6'>Order type: {productType}</th>
                                                                    <th className='col-lg-6 h6'>Material/ component: {productTag} </th>
                                                                </tr>
                                                            </thead>
                                                        )
                                                    default:
                                                        return null
                                                }
                                            })()}

                                        </table>
                                    </div>
                                </div>
                            </div >
                            <hr />

                            {/*--------- Adding the product parameters➕ ---------- */}
                            {(() => {
                                switch (productType) {
                                    case 'Zebra_blinds':
                                    case 'Roller_shades':
                                    case 'Fauxwood':
                                        return (
                                            <>
                                                <h6 className="mb-0 text-uppercase mb-2">Add Product </h6>
                                                {deduction.val && (<NotificationBadge
                                                    colour={'success'}
                                                    icon={<ContentCutIcon />}
                                                    value={deduction.val}
                                                    message1={'A deduction of'}
                                                    message2={'is applying to the width'}
                                                />)}
                                                {addition.val && (<NotificationBadge
                                                    colour={'success'}
                                                    icon={<AddIcon />}
                                                    value={addition.val}
                                                    message1={'An addition of'}
                                                    message2={'is applying to the width'}
                                                />)}
                                                {insertion.val && (<NotificationBadge
                                                    colour={'warning'}
                                                    icon={<SubdirectoryArrowRightIcon />}
                                                    value={insertion.val}
                                                    message1={'Inserting the values to the index'}
                                                    message2={'is active'}
                                                />)}
                                                <div className="row mb-3">
                                                    {/* Input fields */}
                                                    <div className="col-12 col-md-6 col-lg-3 mb-3 mb-md-0">
                                                        <label className="form-label">Width</label>
                                                        <input
                                                            type="text"
                                                            inputMode="decimal"
                                                            pattern="[0-9/]*"
                                                            className="form-control"
                                                            placeholder="Width"
                                                            value={width}
                                                            onChange={(e) => setWidth(e.target.value)}
                                                        />
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-3 mb-3 mb-md-0">
                                                        <label className="form-label">Height</label>
                                                        <input
                                                            type="text"
                                                            inputMode="decimal"
                                                            pattern="[0-9/]*"
                                                            className="form-control"
                                                            placeholder="Height"
                                                            value={height}
                                                            onChange={(e) => setHeight(e.target.value)}
                                                        />
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-3 mb-3 mb-md-0">
                                                        <label className="form-label">Remark</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Remark"
                                                            value={remark}
                                                            onChange={(e) => setRemark(e.target.value)}
                                                        />
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-3 mb-3 mb-md-0">
                                                        <label className="form-label">Location</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Location"
                                                            value={location}
                                                            onChange={(e) => setLocation(e.target.value)}
                                                        />
                                                    </div>

                                                    {/* Button and Dropdown */}
                                                    <div className="col-12 d-flex flex-column flex-md-row justify-content-between mt-3">
                                                        <div className="d-block d-md-none mb-2">
                                                            <button
                                                                type="submit"
                                                                onClick={handleAddDataSubmit}
                                                                className="btn btn-primary w-100"
                                                            >
                                                                Add
                                                            </button>
                                                        </div>
                                                        <div className="d-block d-md-none">
                                                            <MUIDropdown
                                                                toggle={{ dropdownToggle, setDropdownToggle }}
                                                                title={"Actions"}
                                                                actions={
                                                                    <Container>
                                                                        {/* Split🖖🏽 function Button UI */}
                                                                        <MenuItem
                                                                            onClick={(e) => {
                                                                                setDeduction({ trigger: true });
                                                                                setAddition({});
                                                                                setDropdownToggle(false);
                                                                            }}
                                                                        >
                                                                            <ContentCutIcon fontSize="small" sx={{ mr: 0.5 }} />
                                                                            Deduction
                                                                        </MenuItem>
                                                                        {/* Addition➕ function Button UI */}
                                                                        <MenuItem
                                                                            onClick={(e) => {
                                                                                setAddition({ trigger: true });
                                                                                setDeduction({});
                                                                                setDropdownToggle(false);
                                                                            }}
                                                                        >
                                                                            <AddIcon fontSize="small" sx={{ mr: 0.5 }} />
                                                                            Addition
                                                                        </MenuItem>
                                                                        {/* Insertion↳ function Button UI */}
                                                                        <MenuItem
                                                                            onClick={(e) => {
                                                                                setInsertion({ trigger: true });
                                                                                setDropdownToggle(false);
                                                                            }}
                                                                        >
                                                                            <SubdirectoryArrowRightIcon fontSize="small" sx={{ mr: 0.5 }} />
                                                                            Insert in-between
                                                                        </MenuItem>
                                                                        {/* Split 🖖🏽 function Button UI */}
                                                                        <MenuItem
                                                                            disabled={width && height ? false : true}
                                                                            onClick={(e) => {
                                                                                enableSplit(width, splitWidth, setSplitWidth, e);
                                                                                setDropdownToggle(false);
                                                                            }}
                                                                        >
                                                                            <CallSplitIcon fontSize="small" sx={{ mr: 0.5 }} />
                                                                            Split
                                                                        </MenuItem>
                                                                    </Container>
                                                                }
                                                            />
                                                        </div>
                                                        <div className="d-none d-md-block">
                                                            <button
                                                                type="submit"
                                                                onClick={handleAddDataSubmit}
                                                                className="btn btn-primary"
                                                            >
                                                                Add
                                                            </button>
                                                        </div>
                                                        <div className="d-none d-md-block">
                                                            <MUIDropdown
                                                                toggle={{ dropdownToggle, setDropdownToggle }}
                                                                title={"Actions"}
                                                                actions={
                                                                    <Container>
                                                                        {/* Split🖖🏽 function Button UI */}
                                                                        <MenuItem
                                                                            onClick={(e) => {
                                                                                setDeduction({ trigger: true });
                                                                                setAddition({});
                                                                                setDropdownToggle(false);
                                                                            }}
                                                                        >
                                                                            <ContentCutIcon fontSize="small" sx={{ mr: 0.5 }} />
                                                                            Deduction
                                                                        </MenuItem>
                                                                        {/* Addition➕ function Button UI */}
                                                                        <MenuItem
                                                                            onClick={(e) => {
                                                                                setAddition({ trigger: true });
                                                                                setDeduction({});
                                                                                setDropdownToggle(false);
                                                                            }}
                                                                        >
                                                                            <AddIcon fontSize="small" sx={{ mr: 0.5 }} />
                                                                            Addition
                                                                        </MenuItem>
                                                                        {/* Insertion↳ function Button UI */}
                                                                        <MenuItem
                                                                            onClick={(e) => {
                                                                                setInsertion({ trigger: true });
                                                                                setDropdownToggle(false);
                                                                            }}
                                                                        >
                                                                            <SubdirectoryArrowRightIcon fontSize="small" sx={{ mr: 0.5 }} />
                                                                            Insert in-between
                                                                        </MenuItem>
                                                                        {/* Split 🖖🏽 function Button UI */}
                                                                        <MenuItem
                                                                            disabled={width && height ? false : true}
                                                                            onClick={(e) => {
                                                                                enableSplit(width, splitWidth, setSplitWidth, e);
                                                                                setDropdownToggle(false);
                                                                            }}
                                                                        >
                                                                            <CallSplitIcon fontSize="small" sx={{ mr: 0.5 }} />
                                                                            Split
                                                                        </MenuItem>
                                                                    </Container>
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* Split🖖🏽 function Input UI */}
                                                {deduction.trigger && <div className="row mb-3 ">
                                                    <DeductionInput
                                                        setDeduction={setDeduction}
                                                        deduction={deduction}
                                                    />
                                                </div>}
                                                {/* Addition➕ function Input UI */}
                                                {addition.trigger && <div className="row mb-3 ">
                                                    <AdditionInput
                                                        setAddition={setAddition}
                                                        addition={addition}
                                                    />
                                                </div>}
                                                {/* Insertion↳ function Input UI */}
                                                {insertion.trigger && <div className="row mb-3 ">
                                                    <ProductInsertion
                                                        setInsertion={setInsertion}
                                                        insertion={insertion}
                                                    />
                                                </div>}
                                                {/* Split 🖖🏽 function Input UI */}
                                                {splitWidth.trigger && <div className="row mb-3 ">
                                                    <SplitInput setSplitWidth={setSplitWidth}
                                                        splitWidth={splitWidth}
                                                        width={width}
                                                    />
                                                </div>}
                                                <hr />
                                            </>
                                        )
                                    case 'Vertical_blinds':
                                        return (
                                            <>
                                                <h6 className="mb-0 text-uppercase mb-2">Add Product </h6>
                                                {deduction.val && (<NotificationBadge
                                                    colour={'success'}
                                                    icon={<ContentCutIcon />}
                                                    value={deduction.val}
                                                    message1={'A deduction of'}
                                                    message2={'is applying to the width'}
                                                />)}
                                                {addition.val && (<NotificationBadge
                                                    colour={'success'}
                                                    icon={<AddIcon />}
                                                    value={addition.val}
                                                    message1={'An addition of'}
                                                    message2={'is applying to the width'}
                                                />)}
                                                {insertion.val && (<NotificationBadge
                                                    colour={'warning'}
                                                    icon={<SubdirectoryArrowRightIcon />}
                                                    value={insertion.val}
                                                    message1={'Inserting the values to the index'}
                                                    message2={'is active'}
                                                />)}
                                                <div className="row mb-3 ">
                                                    <div className="col-2">
                                                        <label className="form-label">Width</label>
                                                        <input type="text" className="form-control" placeholder="Width"
                                                            value={width} onChange={(e) => setWidth(e.target.value)}
                                                        />
                                                    </div>
                                                    <div className="col-2">
                                                        <label className="form-label">Height</label>
                                                        <input type="text" className="form-control" placeholder="Height"
                                                            value={height} onChange={(e) => setHeight(e.target.value)}
                                                        />
                                                    </div>
                                                    <div className="col-3">
                                                        <label className="form-label">Remark</label>
                                                        <input type="text" className="form-control" placeholder="control"
                                                            value={remark} onChange={(e) => setRemark(e.target.value)}
                                                        />
                                                    </div>
                                                    <div className="col-2">
                                                        <label className="form-label">Location</label>
                                                        <input type="text" className="form-control" placeholder="location"
                                                            value={location} onChange={(e) => setLocation(e.target.value)}
                                                        />
                                                    </div>
                                                    <div className="col-1 mt-4" >
                                                        <button type='submit' onClick={handleAddDataSubmit}
                                                            className="mt-1 btn btn-primary">
                                                            Add
                                                        </button>
                                                    </div>
                                                    <div className="col-1 mt-4" >
                                                        <MUIDropdown
                                                            toggle={{ dropdownToggle, setDropdownToggle }}
                                                            title={"Actions"}
                                                            actions={
                                                                <Container>
                                                                    {/* Split🖖🏽 function Button UI */}
                                                                    <MenuItem
                                                                        onClick={(e) => {
                                                                            setDeduction({ trigger: true })
                                                                            setAddition({})
                                                                            setDropdownToggle(false)
                                                                        }}
                                                                    >
                                                                        <ContentCutIcon fontSize="small" sx={{ mr: 0.5 }} />
                                                                        Deduction
                                                                    </MenuItem>
                                                                    {/* Addition➕ function Button UI */}
                                                                    <MenuItem
                                                                        onClick={(e) => {
                                                                            setAddition({ trigger: true })
                                                                            setDeduction({})
                                                                            setDropdownToggle(false)
                                                                        }}
                                                                    >
                                                                        <AddIcon fontSize="small" sx={{ mr: 0.5 }} />
                                                                        Addition
                                                                    </MenuItem>
                                                                    {/* Insertion↳ function Button UI */}
                                                                    <MenuItem
                                                                        onClick={(e) => {
                                                                            setInsertion({ trigger: true })
                                                                            setDropdownToggle(false)
                                                                        }}
                                                                    >
                                                                        <SubdirectoryArrowRightIcon fontSize="small" sx={{ mr: 0.5 }} />
                                                                        Insert in-between
                                                                    </MenuItem>
                                                                    {/* Split 🖖🏽 function Button UI */}
                                                                    <MenuItem disabled={width && height ? false : true}
                                                                        onClick={(e) => {
                                                                            enableSplit(width, splitWidth, setSplitWidth, e)
                                                                            setDropdownToggle(false)
                                                                        }}>
                                                                        <CallSplitIcon fontSize="small" sx={{ mr: 0.5 }} />
                                                                        Split
                                                                    </MenuItem>
                                                                </Container>
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                {/* Split🖖🏽 function Input UI */}
                                                {deduction.trigger && <div className="row mb-3 ">
                                                    <DeductionInput
                                                        setDeduction={setDeduction}
                                                        deduction={deduction}
                                                    />
                                                </div>}
                                                {/* Addition➕ function Input UI */}
                                                {addition.trigger && <div className="row mb-3 ">
                                                    <AdditionInput
                                                        setAddition={setAddition}
                                                        addition={addition}
                                                    />
                                                </div>}
                                                {/* Insertion↳ function Input UI */}
                                                {insertion.trigger && <div className="row mb-3 ">
                                                    <ProductInsertion
                                                        setInsertion={setInsertion}
                                                        insertion={insertion}
                                                    />
                                                </div>}
                                                {/* Split 🖖🏽 function Input UI */}
                                                {splitWidth.trigger && <div className="row mb-3 ">
                                                    <SplitInput setSplitWidth={setSplitWidth}
                                                        splitWidth={splitWidth}
                                                        width={width}
                                                    />
                                                </div>}
                                                <hr />
                                            </>
                                        )
                                    case 'Materials':
                                        return (
                                            <>
                                                <h6 className="mb-0 text-uppercase mb-2">Add Custom Amount for Product </h6>
                                                <div className="row mb-3">
                                                    <div className="col-4">
                                                        <label className="form-label">Price </label>
                                                        <input type="number" className="form-control" placeholder="$ Amount"
                                                            value={customCost} onChange={(e) => setCustomCost(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    default:
                                        return null
                                }
                            })()}
                        </>
                    )}



                    <br />
                    <br />
                </>
            )}

        </>
    )
}

export default OrderCreate