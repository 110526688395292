import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { Alert, Snackbar } from '@mui/material';
import { createUser } from '../../redux/actions/userManageAction';

const CreateNewUser = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userLogin = useSelector((state) => state.userSignIn);
  const { userInfo } = userLogin;
  
  const userCreate = useSelector((state) => state.createUser);
  const { userLoading, userError } = userCreate;

  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('inhouse_installer');

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertType, setAlertType] = useState('success'); // 'success' or 'error'
  const [alertMessage, setAlertMessage] = useState('');

  useEffect(() => {
    if (!userInfo) {
      navigate('/sign-in');
    }
    window.scrollTo(0, 0);
  }, [userInfo, navigate]);

  useEffect(() => {
    if (userError) {
      setAlertType('error');
      setAlertMessage(userError);
      setAlertOpen(true);
    }
  }, [userError]);

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(createUser({ username, email, password, roles: [role] }, userInfo.token));
    if (!userError) {
      setAlertType('success');
      setAlertMessage('User created successfully!');
      navigate('/user/manage-user');
    } else {
      setAlertType('error');
      setAlertMessage('Failed to create user.');
    }
    setAlertOpen(true);
  };

  return (
    <>
      <div className="row">
        <form onSubmit={handleSubmit}>
          <div className="col-lg-12 mx-auto">
            <div className="card">
              <div className="card-body">
                <h5>User Info</h5>
                <div className="row g-3">
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="form-group">
                      <label className="form-label">Username</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Username"
                        required
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="form-group">
                      <label className="form-label">Email</label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="form-group">
                      <label className="form-label">Password</label>
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Password"
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="form-group">
                      <label className="form-label">Role</label>
                      <select
                        className="form-control"
                        value={role}
                        onChange={(e) => setRole(e.target.value)}
                      >
                        <option value="admin">Admin</option>
                        <option value="production_manager">Production Manager</option>
                        <option value="production_associate">Production Associate</option>
                        <option value="inhouse_installer">Inhouse Installer</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-header py-3 bg-transparent">
                <div className="d-sm-flex align-items-center">
                  <h5 className="mb-2 mb-sm-0">Create New User</h5>
                  <div className="ms-auto">
                    <button type="submit" className="btn btn-primary" disabled={userLoading}>
                      {userLoading ? 'Creating...' : 'Create'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      {/* MUI Snackbar for alerts */}
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity={alertType}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default CreateNewUser;
