import { CREATE_USER_FAIL, CREATE_USER_REQUEST, CREATE_USER_SUCCESS, UPDATE_USER_FAIL, UPDATE_USER_REQUEST, UPDATE_USER_SUCCESS, USER_LIST_FAIL, USER_LIST_REQUEST, USER_LIST_SUCCESS } from "../../constants/UserManageConstants";


export const userListReducer = (state = { users: [] }, action) => {
    switch (action.type) {
        case USER_LIST_REQUEST:
            return { loading: true, users: [] };
        case USER_LIST_SUCCESS:
            return { loading: false, users: action.payload };
        case USER_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

// Update User Reducer
const initialUpdateUserState = {
  userLoading: false,
  user: {},
  userError: null,
};

export const updateUserReducer = (state = initialUpdateUserState, action) => {
  switch (action.type) {
    case UPDATE_USER_REQUEST:
      return { ...state, userLoading: true, userError: null };
    case UPDATE_USER_SUCCESS:
      return { ...state, userLoading: false, user: action.payload, userError: null };
    case UPDATE_USER_FAIL:
      return { ...state, userLoading: false, userError: action.payload };
    default:
      return state;
  }
};


//create user
const initiaCreateUserlState = {
  userLoading: false,
  user: {},
  userError: null,
};

export const createUserReducer = (state = initiaCreateUserlState, action) => {
  switch (action.type) {
    case CREATE_USER_REQUEST:
      return { ...state, userLoading: true, userError: null };
    case CREATE_USER_SUCCESS:
      return { ...state, userLoading: false, user: action.payload, userError: null };
    case CREATE_USER_FAIL:
      return { ...state, userLoading: false, userError: action.payload };
    default:
      return state;
  }
};
