import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  Container,
  Button,
  CircularProgress,
} from "@mui/material";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import CheckIcon from "@mui/icons-material/Check";
import PaymentIcon from "@mui/icons-material/Payment";
import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";
import BusinessIcon from "@mui/icons-material/Business";
import { useDispatch, useSelector } from "react-redux";
import { fetchTransactions } from "../../../redux/actions/TransactionActions";
import { API_URI } from "../../../constants/GlobalConstants";
import fileDownload from "js-file-download";
import axios from "axios";

export default function TransactionTimeline({ id }) {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.userSignIn);
  const { transactions, loading, error } = useSelector(
    (state) => state.transactions
  );
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  // Calculate monthOffset based on selectedMonth and current month
  const monthOffset =
    selectedMonth -
    new Date().getMonth() +
    (selectedYear - new Date().getFullYear()) * 12;

  useEffect(() => {
    if (userInfo && userInfo.token) {
      dispatch(fetchTransactions(id, userInfo.token, 1, monthOffset));
    }
  }, [dispatch, userInfo, selectedMonth, selectedYear, monthOffset]);

  const getIcon = (mode) => {
    switch (mode) {
      case "Cash":
        return <AttachMoneyIcon />;
      case "Credit Card":
        return <CreditCardIcon />;
      case "Bank Transfer":
        return <AccountBalanceIcon />;
      case "Check":
        return <CheckIcon />;
      case "ERP Payment":
        return <PaymentIcon />;
      case "Bank Remittance":
        return <BusinessIcon />;
      default:
        return <AttachMoneyIcon />;
    }
  };

  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = date.toLocaleDateString(undefined, options);
    const formattedTime = date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
    return { date: formattedDate, time: formattedTime };
  };

  const handlePreviousMonth = () => {
    const prevMonth = (selectedMonth + 11) % 12;
    const prevYear = selectedMonth === 0 ? selectedYear - 1 : selectedYear;
    setSelectedMonth(prevMonth);
    setSelectedYear(prevYear);
  };

  const handleNextMonth = () => {
    const nextMonth = (selectedMonth + 1) % 12;
    const nextYear = selectedMonth === 11 ? selectedYear + 1 : selectedYear;
    setSelectedMonth(nextMonth);
    setSelectedYear(nextYear);
  };

  const handleMonthSelect = (event) => {
    const month = parseInt(event.target.getAttribute("data-month"), 10);
    setSelectedMonth(month);
  };

  const handleYearSelect = (event) => {
    const year = parseInt(event.target.getAttribute("data-year"), 10);
    setSelectedYear(year);
  };

  const getMonthName = (month) => {
    return new Date(0, month).toLocaleString("default", { month: "long" });
  };

  const currentMonthName = getMonthName(selectedMonth);
  const nextMonthName = getMonthName((selectedMonth + 1) % 12);
  const previousMonthName = getMonthName((selectedMonth + 11) % 12);

  const handlePrintTransactions = async () => {
    try {
      const response = await axios.get(
        `${API_URI}/api/transaction/transaction-pdf`,
        {
          params: { id, monthOffset },
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
            "Content-Type": "application/pdf",
          },
          responseType: "blob",
        }
      );
      fileDownload(response.data, "transactions.pdf");
    } catch (error) {
      console.error("Error downloading transactions:", error);
    }
  };

  return (
    <Box sx={{ backgroundColor: "#1e1e1e", minHeight: "100vh", py: 5 }}>
      <Container maxWidth="md">
        <Paper sx={{ p: 4, backgroundColor: "#1e1e1e", color: "white" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start", // Align items to the start of the Box
              gap: 2, // Add space between elements
              width: "100%",
            }}
          >
            <Typography
              variant="h4"
              gutterBottom
              align="center"
              sx={{
                fontSize: { xs: "1.5rem", sm: "2rem" }, // Responsive font size
                width: "100%",
              }}
            >
              Transaction History - {currentMonthName} {selectedYear}
            </Typography>

            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" }, // Stack vertically on small screens
                justifyContent: "space-between", // Distribute space evenly
                alignItems: { xs: "flex-start", sm: "center" },
                width: "100%", // Ensure this Box also takes full width
                gap: 2, // Add space between elements
              }}
            >
              {/*Month and Year Dropdown */}
              <div className="dropdown d-inline-block">
                <Button
                  className="btn btn-primary dropdown-toggle"
                  type="button"
                  id="monthDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  sx={{ width: { xs: "100%", sm: "auto" } }} // Full width on small screens
                >
                  {currentMonthName}
                </Button>
                <ul className="dropdown-menu" aria-labelledby="monthDropdown">
                  {Array.from({ length: 12 }, (_, i) => (
                    <li key={i}>
                      <a
                        className="dropdown-item"
                        href="#"
                        data-month={i}
                        onClick={handleMonthSelect}
                      >
                        {new Date(0, i).toLocaleString("default", {
                          month: "long",
                        })}
                      </a>
                    </li>
                  ))}
                </ul>
                <Button
                  className="btn btn-primary dropdown-toggle"
                  type="button"
                  id="yearDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  sx={{ width: { xs: "100%", sm: "auto" } }} // Full width on small screens
                >
                  {selectedYear}
                </Button>
                <ul className="dropdown-menu" aria-labelledby="yearDropdown">
                  {Array.from({ length: 5 }, (_, i) => (
                    <li key={i}>
                      <a
                        className="dropdown-item"
                        href="#"
                        data-year={2020 + i}
                        onClick={handleYearSelect}
                      >
                        {2020 + i}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>

              {/* Print Button */}
              <Button
                onClick={handlePrintTransactions}
                variant="contained"
                color="secondary"
                sx={{
                  mt: { xs: 2, sm: 0 }, // Margin top on small screens
                  width: { xs: "100%", sm: "auto" }, // Full width on small screens
                }}
              >
                Print Transactions
              </Button>
            </Box>
          </Box>

          {loading ? (
            <Box sx={{ display: "flex", justifyContent: "center", py: 4 }}>
              <CircularProgress color="primary" />
            </Box>
          ) : error ? (
            <Typography variant="body1" color="error" align="center">
              Error fetching transactions: {error}
            </Typography>
          ) : (
            <>
              <Timeline
                position="right"
                sx={{ "& .MuiTimelineItem-root": { py: "12px" } }}
              >
                {transactions.map((transaction, index) => {
                  const { date, time } = formatDateTime(transaction.createdAt);

                  return (
                    <TimelineItem key={index}>
                      <TimelineOppositeContent
                        sx={{ m: "auto 0", color: "gray", fontSize: "1rem" }}
                        align="left"
                        variant="body2"
                      >
                        {date}
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineConnector sx={{ backgroundColor: "white" }} />
                        <TimelineDot color="primary">
                          {getIcon(transaction.modeOfPayment)}
                        </TimelineDot>
                        <TimelineConnector sx={{ backgroundColor: "white" }} />
                      </TimelineSeparator>
                      <TimelineContent sx={{ py: "12px", px: 2 }}>
                        <Typography
                          variant="h6"
                          component="span"
                          sx={{ color: "white", fontSize: "1.25rem" }}
                        >
                          ${transaction.amount} Received
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{ color: "gray", fontSize: "1rem" }}
                        >
                          {time} -{" "}
                          {transaction.modeOfPayment ||
                            "Unknown Payment Method"}
                        </Typography>

                        <Box
                          sx={{
                            mt: 2,
                            pl: 2,
                            borderLeft: "2px solid lightgray",
                            color: "lightgray",
                          }}
                        >
                          {transaction.orderDetails.map((order, orderIndex) => (
                            <Box
                              key={orderIndex}
                              sx={{
                                mb: 2,
                                p: 2,
                                backgroundColor: "#333",
                                borderRadius: "8px",
                              }}
                            >
                              <Typography
                                variant="body2"
                                sx={{ fontSize: "1rem", fontWeight: "bold" }}
                              >
                                Product Type: {order.productType}
                              </Typography>
                              <Typography
                                variant="body2"
                                sx={{ fontSize: "1rem" }}
                              >
                                Tag: {order.tag || "No Tag"}
                              </Typography>
                              <Typography
                                variant="body2"
                                sx={{ fontSize: "1rem" }}
                              >
                                Amount Applied: $
                                {order.transactions.amountApplied.toFixed(2) ||
                                  "N/A"}
                              </Typography>
                              <Typography
                                variant="body2"
                                sx={{ fontSize: "1rem" }}
                              >
                                Balance: ${order.outstandingAmount.toFixed(2)}
                              </Typography>
                            </Box>
                          ))}
                        </Box>
                      </TimelineContent>
                    </TimelineItem>
                  );
                })}
              </Timeline>

              {/* Navigation buttons at the bottom */}
              <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
                <Button
                  onClick={handlePreviousMonth}
                  variant="contained"
                  color="primary"
                  sx={{ mr: 2 }}
                >
                  {previousMonthName}
                </Button>
                {monthOffset < 0 && (
                  <Button
                    onClick={handleNextMonth}
                    variant="contained"
                    color="primary"
                  >
                    {nextMonthName}
                  </Button>
                )}
              </Box>
            </>
          )}
        </Paper>
      </Container>
    </Box>
  );
}
