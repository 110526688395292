import axios from "axios";
import { API_URI } from "../../constants/GlobalConstants";
import { CREATE_USER_FAIL, CREATE_USER_REQUEST, CREATE_USER_SUCCESS, UPDATE_USER_FAIL, UPDATE_USER_REQUEST, UPDATE_USER_SUCCESS, USER_LIST_FAIL, USER_LIST_REQUEST, USER_LIST_SUCCESS } from "../../constants/UserManageConstants";


export const fetchUsers = (token) => async (dispatch) => {
  try {
    dispatch({ type: USER_LIST_REQUEST });

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const { data } = await axios.get(
      `${API_URI}/api/user-manage/all-users`,
      config
    );

    dispatch({
      type: USER_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateUser = (userId, updateData, token) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_USER_REQUEST });

    const response = await axios.put(
      `${API_URI}/api/user-manage/update-user/${userId}`,
      updateData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    dispatch({
      type: UPDATE_USER_SUCCESS,
      payload: response.data,
    });
    dispatch(fetchUsers(token));
  } catch (error) {
    dispatch({
      type: UPDATE_USER_FAIL,
      payload: error.response?.data?.message || error.message,
    });
  }
};

export const createUser = (userData, token) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_USER_REQUEST });

    const response = await axios.post(
      `${API_URI}/api/user-manage/create-new-user`,
      userData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    dispatch({
      type: CREATE_USER_SUCCESS,
      payload: response.data,
    });

    dispatch(fetchUsers(token));
  } catch (error) {
    dispatch({
      type: CREATE_USER_FAIL,
      payload: error.response?.data?.message || error.message,
    });
  }
};
