import axios from "axios";
import {
  DEALER_CREATE_FAIL,
  DEALER_CREATE_REQUEST,
  DEALER_CREATE_SUCCESS,
  DEALER_LIST_FAIL,
  DEALER_LIST_REQUEST,
  DEALER_LIST_SUCCESS,
  DEALER_UPDATE_FAIL,
  DEALER_UPDATE_REQUEST,
  DEALER_UPDATE_SUCCESS,
  GET_BALANCE_FAILURE,
  GET_BALANCE_REQUEST,
  GET_BALANCE_SUCCESS,
  GET_DEALER_ORDER_FAILURE,
  GET_DEALER_ORDER_REQUEST,
  GET_DEALER_ORDER_SUCCESS,
} from "../../constants/DealerConstant";
import { API_URI } from "../../constants/GlobalConstants";

export const CreateClientAction = (form, token) => async (dispatch) => {
  try {
    dispatch({ type: DEALER_CREATE_REQUEST });
    const { data } = await axios.post(`${API_URI}/api/dealer/create`, form, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({ type: DEALER_CREATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: DEALER_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const ListDealerAction = (token) => async (dispatch) => {
  try {
    dispatch({ type: DEALER_LIST_REQUEST });
    const { data } = await axios.get(`${API_URI}/api/dealer/list`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({ type: DEALER_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: DEALER_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const ViewDealerAction = (id, token) => async (dispatch) => {
  try {
    dispatch({ type: "DEALER_DETAIL_REQUEST" });
    const { data } = await axios.get(
      `${API_URI}/api/dealer/dealer-detail/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    dispatch({ type: "DEALER_DETAIL_SUCCESS", payload: data });
  } catch (error) {
    dispatch({
      type: "DEALER_DETAIL_FAIL",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const UpdateDealerNotesAction = (val, token) => async (dispatch) => {
  console.log(
    "🚀 ~ file: DealerAction.js:52 ~ UpdateDealerNotesAction ~ val:",
    val
  );
  try {
    dispatch({ type: "DEALER_UPDATE_NOTE_REQUEST" });
    await axios.put(`${API_URI}/api/dealer/update-notes`, val, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({ type: "DEALER_UPDATE_NOTE_SUCCESS" });
  } catch (error) {
    dispatch({
      type: "DEALER_UPDATE_NOTE_FAIL",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

//-----------------------Dealer Order-------------------------
export const DealerOrderStatsAction = (id, token) => async (dispatch) => {
  try {
    dispatch({ type: "DEALER_ORDER_STAT_REQUEST" });
    const { data } = await axios.get(
      `${API_URI}/api/dealer/order-overview/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({ type: "DEALER_ORDER_STAT_SUCCESS", payload: data });
  } catch (error) {
    dispatch({
      type: "DEALER_ORDER_STAT_FAIL",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

//-----------------------Dealer Orders list--------------------

export const getDealerOrders = (variables) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_DEALER_ORDER_REQUEST });
    const { userInfo } = getState().userSignIn;
    const { data } = await axios.post(
      `${API_URI}/api/order/order-by-dealer?month=${variables.month}`,
      variables,
      {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
    );

    dispatch({
      type: GET_DEALER_ORDER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_DEALER_ORDER_FAILURE,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const fetchBalance = (dealerId, month) => async (dispatch, getState) => {
  dispatch({ type: GET_BALANCE_REQUEST });

  try {
    const { userInfo } = getState().userSignIn;

    const response = await axios.get(`${API_URI}/api/order/balance-amount`, {
      params: { month, dealerId },
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    });

    dispatch({
      type: GET_BALANCE_SUCCESS,
      payload: {
        currentMonthOutstanding: response.data.currentMonthOutstanding,
        overallTotalOutstanding: response.data.overallTotalOutstanding,
      },
    });
  } catch (error) {
    dispatch({
      type: GET_BALANCE_FAILURE,
      payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message,
    });
  }
};

// Update dealer profile

export const updateDealerProfile = (id, dealerData, token) => async (dispatch) => {
  try {
      dispatch({ type: DEALER_UPDATE_REQUEST });

      const config = {
          headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`
          }
      };

      const { data } = await axios.put(`${API_URI}/api/dealer/profile-edit/${id}`, dealerData, config);

      dispatch({ type: DEALER_UPDATE_SUCCESS, payload: data });
  } catch (error) {
      dispatch({
          type: DEALER_UPDATE_FAIL,
          payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      });
  }
};