import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnnualSaleAnalAction } from '../../../redux/actions/AnalyticsActions';
import { ListDealerAction } from '../../../redux/actions/DealerAction';
import Loader from '../../../components/layout/Loader';
import LoaderLin from '../../../components/layout/LoaderLin';
import LineChart from '../../../components/analytics/sales/LineChart';

const SalesAnalyticsContent = () => {
  const dispatch = useDispatch();
  const userLogin = useSelector(state => state.userSignIn);
  const { userInfo } = userLogin;
  const annualSaleAnal = useSelector(state => state.annualSaleAnal);
  const { loading, data } = annualSaleAnal;
  const listDealer = useSelector(state => state.listDealer);
  const { dealer, loading: loadingDealer } = listDealer;

  const [dealerName, setDealerName] = useState();

  useEffect(() => {
    if (userInfo) {
      dispatch(ListDealerAction(userInfo.token));
      dispatch(AnnualSaleAnalAction({ companyName: dealerName, token: userInfo.token }));
    }
  }, [dealerName, userInfo, dispatch]);

  console.log("Annual Sale Data:", data);
  console.log("Dealer List:", dealer);

  if (loading || loadingDealer) return <Loader />;
  
  return (
    <div className="row">
      <div className="col-xl-12 mx-auto">
        <div className='row'>
          <div className="col-md-8 col-lg-8 col-xl-8">
            <h6 className="mb-0"><i className="fadeIn animated bx bx-line-chart" /> Annual sales sorted by dealer - Line Graph</h6>
          </div>
          <div className="col-md-4 col-lg-4 col-xl-4 mb-2">
            {
              loadingDealer ? (<LoaderLin />) : (
                <select className="form-select" value={dealerName} onChange={(e) => setDealerName(e.target.value)}>
                  {!dealerName && <option>Inhouse</option>}
                  {dealer && dealer.map((c) => (
                    <option key={c._id} value={c._id}>{c.companyName}</option>
                  ))}
                </select>
              )
            }
          </div>
        </div>
        {!data ? <Loader /> : <LineChart analData={data} />}
      </div>
    </div>
  );
};


export default SalesAnalyticsContent;
