import React from 'react'

const OrderOverviewUI = ({ productType, orderFactor, productMaterial }) => {

    return (
        <>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        {(() => {
                            switch (productType) {
                                case 'Zebra_blinds':
                                case 'Roller_shades':
                                    return (
                                        <>
                                            <div className="col-lg-3 h6 pt-1 text-success">
                                                {productType}
                                            </div>
                                            <div className="col-lg-3 h6 pt-1">
                                                <span className='text-info'>Total SQFT:&ensp;</span>
                                                &ensp;
                                                {orderFactor}
                                            </div>
                                            <div className="col-lg-3 h6 pt-1">
                                                <span className='text-warning'>
                                                    Material:&ensp;</span> {productMaterial}
                                            </div>
                                            <div className="col-lg-3 h6 pt-1">
                                                Efficient Cut
                                            </div>
                                        </>
                                    )
                                case 'Fauxwood':
                                    return (
                                        <>
                                            <div className="col-lg-3 h6 pt-1">
                                                <span className='text-success'>
                                                    {productType}
                                                </span>
                                            </div>
                                            <div className="col-lg-3 h6 pt-1">
                                                <span className='text-primary'>
                                                    Total Slat:
                                                </span>
                                                {/* &ensp;{counter} */}
                                            </div>
                                            <div className="col-lg-3 h6 pt-1">
                                                <span className='text-info'>
                                                    Total Var:&ensp;
                                                </span>
                                                {orderFactor}
                                            </div>
                                            <div className="col-lg-3 h6 pt-1">
                                                <span className='text-warning'>
                                                    Material:&ensp;</span> {productMaterial}
                                            </div>
                                        </>
                                    )
                                case 'Vertical_blinds':
                                    return (
                                        <>
                                            <div className="col-lg-3 h6 pt-1">
                                                <span className='text-success'>
                                                    {productType}
                                                </span>
                                            </div>
                                            <div className="col-lg-3 h6 pt-1">
                                                <span className='text-info'>
                                                    Total Var:&ensp;
                                                </span>
                                                {orderFactor}
                                            </div>
                                            <div className="col-lg-3 h6 pt-1">
                                                <span className='text-warning'>
                                                    Materials:&ensp;</span> {productMaterial}
                                            </div>
                                        </>
                                    )
                                case 'Materials':
                                    return (
                                        <>
                                            <div className="col-lg-3 h6 pt-1">
                                                {productType}
                                            </div>
                                            <div className="col-lg-3 h6 pt-1">
                                                Mention the  type of Material in product tag
                                            </div>
                                        </>
                                    )
                                default:
                                    return null
                            }
                        })()}
                    </div>
                </div>
            </div>
        </>
    )
}

export default OrderOverviewUI