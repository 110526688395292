import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRoute = ({ element: Component, roles, ...rest }) => {
  const { userInfo } = useSelector(state => state.userSignIn);

  if (!userInfo) {
    return <Navigate to="/login" />;
  }

  if (roles && !roles.includes(userInfo.roles[0]) && !userInfo.isAdmin) {
    return <Navigate to="/" />;
  }

  return <Component {...rest} />;
};

export default PrivateRoute;
