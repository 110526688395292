import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  Alert,
  Snackbar,
  Modal,
  Box,
  TextField,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import Loader from "../layout/Loader";
import { fetchUsers, updateUser } from "../../redux/actions/userManageAction";

const ManageUsers = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userSignIn);
  const { userInfo } = userLogin;
  const { userLoading, success, userError } = useSelector((state) => state.updatedUser);
  const [selectedUser, setSelectedUser] = useState(null);
  const [newPassword, setNewPassword] = useState("");
  const [role, setRole] = useState("");
  const userList = useSelector((state) => state.userList);
  const { users, loading, error } = userList;
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertType, setAlertType] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [updateTrigger, setUpdateTrigger] = useState(0);

  useEffect(() => {
    if (!userInfo) {
      navigate("/sign-in");
    } else {
      dispatch(fetchUsers(userInfo.token));
    }
    window.scrollTo(0, 0);
  }, [dispatch, navigate, userInfo]);

  useEffect(() => {
    if (success) {
      setAlertType("success");
      setAlertMessage("Update successful!");
      setAlertOpen(true);
      handleModalClose();
    } else if (userError) {
      setAlertType("error");
      setAlertMessage(userError);
      setAlertOpen(true);
    }
  }, [success, userLoading, dispatch, userInfo.token]);

  const handleEdit = (userId) => {
    navigate(`/user/edit-user/${userId}`);
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setRole("");
    setNewPassword("");
    setSelectedUser(null);
  };

  const handleUpdateRole = () => {
    if (!role) {
      setAlertType("error");
      setAlertMessage("Please select a role.");
      setAlertOpen(true);
      return;
    }
    dispatch(updateUser(selectedUser, { roles: [role] }, userInfo.token));
    handleModalClose(); 
  };

  const handleUpdatePassword = () => {
    if (!newPassword) {
      setAlertType("error");
      setAlertMessage("Please enter a new password.");
      setAlertOpen(true);
      return;
    }
    dispatch(updateUser(selectedUser, { password: newPassword }, userInfo.token));
    handleModalClose(); 
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-12 mx-auto">
          <div className="card">
            <div className="card-header py-3 bg-transparent">
              <div className="d-sm-flex align-items-center">
                <h5 className="mb-2 mb-sm-0">Manage Users</h5>
                <div className="ms-auto">
                  <button
                    className="btn btn-primary"
                    onClick={() => navigate("/user/add-user")}
                  >
                    Add New User
                  </button>
                </div>
              </div>
            </div>
            <div className="card-body">
              {loading ? (
                <Loader />
              ) : error ? (
                <Alert severity="error">{error}</Alert>
              ) : (
                <div>
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Username</th>
                          <th>Email</th>
                          <th>Roles</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {users.length > 0 ? (
                          users.map((user, index) => (
                            <tr key={user._id}>
                              <td>{index + 1}</td>
                              <td>{user.username}</td>
                              <td>{user.email}</td>
                              <td>
                                {user.isAdmin ? "admin" : user.roles.join(", ")}
                              </td>
                              <td>
                                <button
                                  className="btn btn-sm btn-info me-2"
                                  onClick={() => handleEdit(user._id)}
                                >
                                  Edit
                                </button>
                                <button
                                  className="btn btn-sm btn-warning me-2"
                                  onClick={() => {
                                    setSelectedUser(user._id);
                                    setModalType("updateRole");
                                    setModalOpen(true);
                                  }}
                                >
                                  Update Role
                                </button>
                                <button
                                  className="btn btn-sm btn-secondary"
                                  onClick={() => {
                                    setSelectedUser(user._id);
                                    setModalType("changePassword");
                                    setModalOpen(true);
                                  }}
                                >
                                  Change Password
                                </button>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="5">No users found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Alert Snackbar */}
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity={alertType}>
          {alertMessage}
        </Alert>
      </Snackbar>

      {/* Modal for updating role or changing password */}
      {selectedUser && (
        <Modal
          open={modalOpen}
          onClose={handleModalClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title" id="modal-title">
                {modalType === "updateRole" ? "Update Role" : "Change Password"}
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={handleModalClose}
              ></button>
            </div>
            <div className="modal-body">
              {modalType === "updateRole" && (
                <div>
                  <h6>Update Role</h6>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="role-select-label">Role</InputLabel>
                    <Select
                      labelId="role-select-label"
                      value={role}
                      onChange={(e) => setRole(e.target.value)}
                      label="Role"
                    >
                      <MenuItem value="admin">Admin</MenuItem>
                      <MenuItem value="production_manager">
                        Production Manager
                      </MenuItem>
                      <MenuItem value="production_associate">
                        Production Associate
                      </MenuItem>
                      <MenuItem value="inhouse_installer">
                        In-House Installer
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleUpdateRole}
                    sx={{ mt: 2 }}
                  >
                    Update Role
                  </Button>
                </div>
              )}

              {modalType === "changePassword" && (
                <div>
                  <h6>Change Password</h6>
                  <TextField
                    fullWidth
                    type="password"
                    label="New Password"
                    variant="outlined"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    sx={{ mb: 2 }}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleUpdatePassword}
                  >
                    Change Password
                  </Button>
                </div>
              )}
            </div>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default ManageUsers;
