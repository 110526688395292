export const widthArray = [24, 36, 50, 56, 62, 75, 81, 87, 94, 100, 112, 125, 137, 150, 162, 175, 192]
export const heightArray = [36, 48, 60, 72, 85, 96, 108, 120, 132, 144]
export const wMatrix = [
    [114, 124, 133, 142, 152, 161, 170, 179, 189, 198],
    [142, 156, 170, 184, 199, 212, 226, 240, 253, 267],
    [170, 188, 207, 226, 246, 263, 281, 300, 318, 337],
    [184, 205, 226, 246, 269, 288, 309, 330, 351, 372],
    [198, 221, 244, 267, 292, 314, 337, 360, 383, 407],
    [228, 256, 283, 311, 341, 367, 395, 423, 451, 478],
    [242, 272, 302, 332, 365, 392, 423, 453, 483, 513],
    [255, 288, 320, 353, 388, 418, 450, 483, 515, 548],
    [286, 321, 356, 391, 428, 460, 495, 530, 565, 600],
    [301, 338, 375, 412, 453, 487, 524, 561, 598, 635],
    [330, 372, 414, 456, 501, 549, 584, 623, 665, 707],
    [360, 406, 453, 499, 549, 592, 644, 685, 731, 778],
    [389, 440, 492, 543, 598, 645, 696, 747, 798, 849],
    [419, 475, 530, 586, 646, 697, 753, 809, 865, 920]
    [450, 510, 570, 631, 696, 751, 812, 872, 933, 993],
    [479, 544, 609, 674, 745, 804, 869, 934, 999, 1064],
    [522, 594, 666, 738, 816, 882, 954, 1026, 1095, 1170]
]