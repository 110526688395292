import React, { useState } from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CreateClientAction } from '../../redux/actions/DealerAction';
import Badge from '../layout/Badge';
import Loader from '../layout/Loader';
import { useNavigate } from 'react-router';

const CreateNewDealer = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const userLogin = useSelector(state => state.userSignIn)
    const { error, userInfo } = userLogin
    const addDealer = useSelector((state) => state.createDealer)
    const { loading, success } = addDealer


    const [name, setName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState(null);
    const [address, setAddress] = useState('');
    const [type, setType] = useState('');
    const [fauxwoodRate, setFauxwoodRate] = useState(0);
    const [rollerRate, setRollerRate] = useState(0);
    const [zebraRate, setZebraRate] = useState(0);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');


    const handleSubmit = (e) => {
        e.preventDefault()
        const form = {
            name, companyName,
            email, phone,
            address, type, fauxwoodRate,
            rollerRate, zebraRate, username,
            password
        }
        dispatch(CreateClientAction(form,userInfo.token))
    }

    useEffect(() => {
        if (!userInfo) {
            navigate('/sign-in')
        }
        window.scrollTo(0, 0)
    }, [userInfo, navigate])

    useEffect(() => {
        dispatch({ type: 'DEALER_CREATE_RESET' })
    }, []);

    return (
        <>
            {success ? (<Badge p={{ type: 'success', message: 'New Dealer has been created' }} />) : (
                loading ? (<Loader />) : (
                    <div className="row">
                        <form onSubmit={handleSubmit}>
                            <div className="col-lg-12 mx-auto">
                                <div className="card">
                                    <div className="card-body">
                                        <h5>Dealer Info</h5>
                                        <div className="row g-3">
                                            <div className="col-12 col-lg-12">
                                                <div className="card shadow-none bg-light border">
                                                    <div className="card-body">
                                                        <div className="row g-3">
                                                            <div className="col-6">
                                                                <label className="form-label"> Name</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Client Name" required
                                                                    value={name} onChange={(e) => setName(e.target.value)}
                                                                />
                                                            </div>
                                                            <div className="col-6">
                                                                <label className="form-label"> Company</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Company" required
                                                                    value={companyName} onChange={(e) => setCompanyName(e.target.value)}
                                                                />
                                                            </div>
                                                            <div className="col-6">
                                                                <label className="form-label"> email</label>
                                                                <input
                                                                    type="email"
                                                                    className="form-control"
                                                                    placeholder="Client email"
                                                                    value={email} onChange={(e) => setEmail(e.target.value)}
                                                                />
                                                            </div>
                                                            <div className="col-4">
                                                                <label className="form-label">Phone</label>
                                                                <input
                                                                    type="telephone"
                                                                    className="form-control"
                                                                    placeholder="Client Phone" required
                                                                    value={phone} onChange={(e) => setPhone(e.target.value)}
                                                                />
                                                            </div>
                                                            <div className="col-4">
                                                                <label className="form-label">address</label>
                                                                <input
                                                                    type="telephone"
                                                                    className="form-control"
                                                                    placeholder="Client Address"
                                                                    value={address} onChange={(e) => setAddress(e.target.value)}
                                                                />
                                                            </div>
                                                            <div className="col-4">
                                                                <label className="form-label">Type</label>
                                                                <select className="form-select" required
                                                                    value={type} onChange={(e) => setType(e.target.value)}>
                                                                    <option></option>
                                                                    <option value='general'>General</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h5>Account Details</h5>
                                        <div className="row g-3">
                                            <div className="col-12 col-lg-12">
                                                <div className="card shadow-none bg-light border">
                                                    <div className="card-body">
                                                        <div className="row g-3">
                                                            <div className="col-4">
                                                                <label className="form-label"> Fauxwood Rate</label>
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    placeholder="" required
                                                                    value={fauxwoodRate} onChange={(e) => setFauxwoodRate(e.target.value)}
                                                                />
                                                            </div>
                                                            <div className="col-4">
                                                                <label className="form-label"> Zebra Blinds</label>
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    placeholder="" required
                                                                    value={zebraRate} onChange={(e) => setZebraRate(e.target.value)}
                                                                />
                                                            </div>
                                                            <div className="col-4">
                                                                <label className="form-label"> Roller Shades</label>
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    placeholder=""
                                                                    value={rollerRate} onChange={(e) => setRollerRate(e.target.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h5>Account Credential</h5>
                                        <div className="row g-3">
                                            <div className="col-12 col-lg-12">
                                                <div className="card shadow-none bg-light border">
                                                    <div className="card-body">
                                                        <div className="row g-3">
                                                            <div className="col-6">
                                                                <label className="form-label"> Account username</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Username" required
                                                                    value={username} onChange={(e) => setUsername(e.target.value)}
                                                                />
                                                            </div>
                                                            <div className="col-6">
                                                                <label className="form-label"> password</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Password" required
                                                                    value={password} onChange={(e) => setPassword(e.target.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*end row*/}
                                    </div>
                                    <div className="card-header py-3 bg-transparent">
                                        <div className="d-sm-flex align-items-center">
                                            <h5 className="mb-2 mb-sm-0">Create New Dealer</h5>
                                            <div className="ms-auto">
                                                <button type="submit"
                                                    className="btn btn-primary"
                                                    onSubmit={handleSubmit}
                                                >
                                                    Create
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                )
            )
            }
        </>
    )
}

export default CreateNewDealer