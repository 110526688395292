import axios from "axios";
import { FETCH_CHART_DATA_FAILURE, FETCH_CHART_DATA_REQUEST, FETCH_CHART_DATA_SUCCESS, FETCH_DASHBOARD_DATA_FAILURE, FETCH_DASHBOARD_DATA_REQUEST, FETCH_DASHBOARD_DATA_SUCCESS } from "../../constants/DashboardConstants";
import { API_URI } from "../../constants/GlobalConstants";

//Dashboard Data 
export const fetchDashboardData = (token) => async (dispatch) => {
    dispatch({ type: FETCH_DASHBOARD_DATA_REQUEST });
    try {
      const response = await axios.get(`${API_URI}/api/order/dashboard-data`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch({
        type: FETCH_DASHBOARD_DATA_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: FETCH_DASHBOARD_DATA_FAILURE,
        payload: error.message,
      });
    }
  };

  export const fetchChartData = (token, monthIndex, year) => async (dispatch) => {
    dispatch({ type: FETCH_CHART_DATA_REQUEST });
    try {
      const response = await axios.get(`${API_URI}/api/order/product-type-statistics`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          month: monthIndex,
          year,
        },
      });
      dispatch({
        type: FETCH_CHART_DATA_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: FETCH_CHART_DATA_FAILURE,
        payload: error.message,
      });
    }
  };