// src/components/PieChartWithLegend.jsx
import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import { Chart, ArcElement, Tooltip, Legend } from "chart.js";
import { useSelector, useDispatch } from "react-redux";
import { fetchChartData } from "../../../redux/actions/DashboarActions";
import Loader from "../../layout/Loader";

Chart.register(ArcElement, Tooltip, Legend);

const PieChartWithLegend = () => {
  const currentDate = new Date();
  const currentMonthName = currentDate.toLocaleString('default', { month: 'long' }); 
  const currentYear = currentDate.getFullYear();

  const [selectedMonth, setSelectedMonth] = useState(currentMonthName);
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const dispatch = useDispatch();
  const { data, loading, error } = useSelector((state) => state.chart);
  const userLogin = useSelector((state) => state.userSignIn);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (userInfo?.token) {
      const monthIndex = new Date(Date.parse(selectedMonth + " 1, " + selectedYear)).getMonth();
      dispatch(fetchChartData(userInfo.token, monthIndex, selectedYear));
    }
  }, [selectedMonth, selectedYear, userInfo?.token, dispatch]);

  const pieData = {
    labels: data.length ? data.map((item) => item.type) : [],
    datasets: [
      {
        label: "Product Type Distribution",
        data: data.length ? data.map((item) => item.count) : [],
        backgroundColor: [
          "rgba(255, 99, 132, 0.8)",
          "rgba(54, 162, 235, 0.8)",
          "rgba(255, 206, 86, 0.8)",
          "rgba(75, 192, 192, 0.8)",
          "rgba(153, 102, 255, 0.8)",
          "rgba(255, 159, 64, 0.8)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            const label = tooltipItem.label || "";
            const total = pieData.datasets[0].data.reduce((acc, val) => acc + val, 0);
            const percentage = ((tooltipItem.raw / total) * 100).toFixed(2);
            return `${label}: ${percentage}%`;
          },
        },
      },
    },
    maintainAspectRatio: false,
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  return (
    <div
      style={{
        padding: "20px",
        borderRadius: "8px",
        boxShadow: "0 0 10px rgba(0,0,0,0.1)",
        backgroundColor: "#16122e",
      }}
    >
      <div className="d-flex flex-column flex-md-row justify-content-between align-items-start mb-4">
        <div>
          <h2 style={{ color: "#fff" }}>Product Type Distribution</h2>
          <p style={{ color: "#bbb" }}>Total Orders Sorted by Product Type</p>
        </div>
        <div className="d-flex flex-column flex-md-row">
          <div className="mb-2 mb-md-0 me-md-3">
            <label htmlFor="month-select" style={{ color: "#bbb" }}>
              Select Month:{" "}
            </label>
            <select
              id="month-select"
              value={selectedMonth}
              onChange={handleMonthChange}
              style={{
                backgroundColor: "#444",
                color: "#fff",
                border: "1px solid #555",
                borderRadius: "4px",
                padding: "5px",
              }}
            >
              <option value="January">January</option>
              <option value="February">February</option>
              <option value="March">March</option>
              <option value="April">April</option>
              <option value="May">May</option>
              <option value="June">June</option>
              <option value="July">July</option>
              <option value="August">August</option>
              <option value="September">September</option>
              <option value="October">October</option>
              <option value="November">November</option>
              <option value="December">December</option>
            </select>
          </div>
          <div className="mb-2 mb-md-0">
            <label htmlFor="year-select" style={{ color: "#bbb", marginLeft: "20px" }}>
              Select Year:{" "}
            </label>
            <select
              id="year-select"
              value={selectedYear}
              onChange={handleYearChange}
              style={{
                backgroundColor: "#444",
                color: "#fff",
                border: "1px solid #555",
                borderRadius: "4px",
                padding: "5px",
              }}
            >
              <option value={currentYear}>{currentYear}</option>
              <option value={currentYear - 1}>{currentYear - 1}</option>
            </select>
          </div>
        </div>
      </div>
      <div className="d-flex flex-column flex-md-row">
        <div className="flex-grow-1" style={{ height: "300px" }}>
          {loading ? (
            <div style={{ textAlign: "center", color: "#fff", paddingTop: "100px" }}>
              <Loader />
            </div>
          ) : error ? (
            <div style={{ textAlign: "center", color: "#fff", paddingTop: "100px" }}>
              <h4>{error}</h4>
            </div>
          ) : data.length ? (
            <Pie data={pieData} options={options} />
          ) : (
            <div style={{ textAlign: "center", color: "#fff", paddingTop: "100px" }}>
              <h4>No Data Available</h4>
            </div>
          )}
        </div>
        <div className="flex-shrink-1" style={{ paddingLeft: "20px", marginTop: "20px" }}>
          {data.length ? (
            <>
              <h5 style={{ color: "#fff" }}>Legend</h5>
              <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
                {pieData.labels.map((label, index) => (
                  <li
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "10px",
                      color: "#fff",
                    }}
                  >
                    <span
                      style={{
                        display: "inline-block",
                        width: "20px",
                        height: "20px",
                        backgroundColor: pieData.datasets[0].backgroundColor[index],
                        marginRight: "10px",
                      }}
                    ></span>
                    {label}
                  </li>
                ))}
              </ul>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default PieChartWithLegend;
