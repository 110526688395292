import React, { useEffect, useState } from 'react'
import { TextField } from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useDispatch, useSelector } from 'react-redux';
import { CreateAppointAction } from '../../redux/actions/AppointActions';
import Loader from '../layout/Loader';
import Badge from '../layout/Badge';
import { useNavigate } from 'react-router';



const ActionCreate = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const userLogin = useSelector(state => state.userSignIn)
    const { error, userInfo } = userLogin
    const newAppoint = useSelector((state) => state.appointCreate)
    const { loading, success } = newAppoint

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [address, setAddress] = useState('')
    const [des, setDes] = useState('')
    const [action, setAction] = useState('')
    const [payment, setPayment] = useState(0.00)
    const [timing, setTiming] = useState(dayjs());

    const handleSubmit = (event) => {
        event.preventDefault();
        const form = {
            name,
            email,
            phone,
            address,
            des,
            timing: timing.$d,
            action,
            payment,
        }
        dispatch(CreateAppointAction(form,userInfo.token))

    }
    useEffect(() => {
        if (!userInfo) {
            navigate('/sign-in')
        }
        window.scrollTo(0, 0)
    }, [userInfo, navigate])

    return (
        <>
            {success ?
                (<Badge p={{ type: 'success', message: 'New Action has been created' }} />) :
                loading ? (<Loader />) : (
                    <div className="row">
                        <form onSubmit={handleSubmit}>
                            <div className="col-lg-12 mx-auto">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row g-3">
                                            <div className="col-12 col-lg-8">
                                                <div className="card shadow-none bg-light border">
                                                    <div className="card-body">
                                                        <div className="row g-3">
                                                            <div className="col-6">
                                                                <label className="form-label"> Name</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Client Name"
                                                                    required
                                                                    value={name} onChange={(e) => setName(e.target.value)}
                                                                />
                                                            </div>
                                                            <div className="col-6">
                                                                <label className="form-label"> email</label>
                                                                <input
                                                                    type="email"
                                                                    className="form-control"
                                                                    placeholder="Client email"
                                                                    value={email} onChange={(e) => setEmail(e.target.value)}
                                                                />
                                                            </div>
                                                            <div className="col-4">
                                                                <label className="form-label">Phone</label>
                                                                <input
                                                                    type="telephone"
                                                                    className="form-control"
                                                                    placeholder="Client Phone"
                                                                    value={phone} onChange={(e) => setPhone(e.target.value)}
                                                                />
                                                            </div>
                                                            <div className="col-8">
                                                                <label className="form-label">Address</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Client address"
                                                                    value={address} onChange={(e) => setAddress(e.target.value)}
                                                                />
                                                            </div>

                                                            <div className="col-12">
                                                                <label className="form-label">Full description</label>
                                                                <textarea
                                                                    className="form-control"
                                                                    placeholder="Full description"
                                                                    rows={6}
                                                                    cols={4}
                                                                    defaultValue={""}
                                                                    value={des} onChange={(e) => setDes(e.target.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-4">
                                                <div className="card shadow-none bg-light border">
                                                    <div className="card-body">
                                                        <div className="row g-3">
                                                            <div className="col-12">
                                                                <div className="bg-secondary p-2">
                                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                        <DateTimePicker
                                                                            renderInput={(props) => <TextField {...props} />}
                                                                            label="DateTimePicker"
                                                                            value={timing}
                                                                            onChange={(newValue) => {
                                                                                setTiming(newValue);
                                                                            }}
                                                                        />
                                                                    </LocalizationProvider>
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <label className="form-label">Action</label>
                                                                <select className="form-select"
                                                                    required
                                                                    value={action} onChange={(e) => setAction(e.target.value)}>
                                                                    <option></option>
                                                                    <option value='General-Inquiry'>General Inquiry</option>
                                                                    <option value='Quotation'>Quotation</option>
                                                                </select>
                                                            </div>
                                                            {
                                                                action == 'Quotation' && (
                                                                    <div className="col-12">
                                                                        <label className="form-label">Quote</label>
                                                                        <input
                                                                            type="number"
                                                                            className="form-control"
                                                                            placeholder="Payment"
                                                                            pattern="^\d*(\.\d{0,2})?$"
                                                                            value={payment} onChange={(e) => setPayment(e.target.value)}
                                                                        />
                                                                    </div>
                                                                )
                                                            }

                                                        </div>
                                                        {/*end row*/}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*end row*/}
                                    </div>
                                    <div className="card-header py-3 bg-transparent">
                                        <div className="d-sm-flex align-items-center">
                                            <h5 className="mb-2 mb-sm-0">Create New Action</h5>
                                            <div className="ms-auto">
                                                <button type="submit"
                                                    className="btn btn-primary"
                                                    onSubmit={handleSubmit}>
                                                    Create Action
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </form>
                    </div>
                )}
        </>
    )
}

export default ActionCreate