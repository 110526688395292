import React from 'react';
import PropTypes from 'prop-types';
import { 
    Table, 
    TableContainer, 
    TableHead, 
    TableBody, 
    TableRow, 
    TableCell, 
    Paper, 
    Typography, 
    LinearProgress, 
    Box 
} from '@mui/material';

const CategoryTable = ({ item, showUsage }) => {
    const calculateUsagePercentage = (totalStock = 0, remainingInStock = 0) => {
        if (typeof totalStock !== 'number' || typeof remainingInStock !== 'number' || totalStock === 0) return 0;
        const usedStock = totalStock - remainingInStock;
        return (usedStock / totalStock) * 100;
    };

    const calculateRemainingPercentage = (totalStock = 0, remainingInStock = 0) => {
        if (typeof totalStock !== 'number' || typeof remainingInStock !== 'number' || totalStock === 0) return 0;
        return (remainingInStock / totalStock) * 100;
    };


    const getProgressColor = (percentage) => {
        if (percentage >= 80) return 'green'; 
        if (percentage >= 60) return 'yellow'; 
        if (percentage >= 40) return 'orange';
        if (percentage >= 15) return 'deeppink'; 
        return 'red'; 
    };

    return (
        <Paper elevation={3} sx={{ marginBottom: 4, padding: 2 }}>
            <Typography variant="h5" component="h2" gutterBottom>
                {item.category} Components
            </Typography>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>In Stock</TableCell>
                            <TableCell>Stock Type</TableCell>
                            {item.items[0].remainingInStock <= item.items[0].stockAlert && <TableCell>Alert</TableCell>}
                            <TableCell>Supported Types</TableCell>
                            <TableCell>Usage</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {item.items.map((itemDetail) => {
                            const percentage = showUsage 
                                ? calculateUsagePercentage(itemDetail.totalStock, itemDetail.remainingInStock) 
                                : calculateRemainingPercentage(itemDetail.totalStock, itemDetail.remainingInStock);

                            return (
                                <TableRow key={itemDetail.name}>
                                    <TableCell>{itemDetail.name}</TableCell>
                                    <TableCell>{itemDetail.remainingInStock}</TableCell>
                                    <TableCell>{itemDetail.stockType}</TableCell>
                                    {itemDetail.remainingInStock <= itemDetail.stockAlert && (
                                        <TableCell>
                                            <Box component="span" 
                                                sx={{ 
                                                    backgroundColor: itemDetail.remainingInStock <= itemDetail.stockAlert ? 'error.main' : 'success.main', 
                                                    color: 'white', 
                                                    borderRadius: 1, 
                                                    padding: '2px 8px' 
                                                }}>
                                                {itemDetail.stockAlert}
                                            </Box>
                                        </TableCell>
                                    )}
                                    <TableCell>{itemDetail.proDependency.join(', ')}</TableCell>
                                    <TableCell>
                                        <LinearProgress 
                                            variant="determinate" 
                                            value={percentage} 
                                            sx={{ 
                                                height: 16, 
                                                borderRadius: 1, 
                                                '& .MuiLinearProgress-bar': {
                                                    backgroundColor: getProgressColor(percentage)
                                                }
                                            }} 
                                        />
                                        <Box sx={{ textAlign: 'center', mt: 1 }}>
                                            {Math.round(percentage)}% {showUsage ? 'Used' : 'Remaining'}
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
};

CategoryTable.propTypes = {
    item: PropTypes.shape({
        category: PropTypes.string.isRequired,
        items: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string.isRequired,
            remainingInStock: PropTypes.number.isRequired,
            totalStock: PropTypes.number.isRequired,
            category: PropTypes.string.isRequired,
            proDependency: PropTypes.arrayOf(PropTypes.string).isRequired,
            stockAlert: PropTypes.number.isRequired,
            stockType: PropTypes.string.isRequired,
        })).isRequired
    }).isRequired,
    showUsage: PropTypes.bool
};

export default CategoryTable;
