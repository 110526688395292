import { IconButton, Tooltip } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"; // Import the HelpOutlineIcon

const StockDetailsForm = ({ formData, handleChange }) => {
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12 col-lg-8 px-4">
          <div className="card shadow-none bg-light border">
            <div className="card-body">
              <h5 className="card-title mb-4 text-warning">Stock Detail</h5>
              <div className="row g-3">
                <div className="col-12">
                  <label className="form-label">
                    Stock Type
                    <Tooltip
                      title={
                        <span style={{ fontSize: ".8rem" }}>
                          In which the item is stored in the warehouse. Stock
                          Type (e.g., Brackets come in boxes, therefore, the{" "}
                          <span className="text-warning">Stock Type</span> will
                          be “box”)
                        </span>
                      }
                      arrow
                    >
                      <IconButton size="small" sx={{ color: "#fff" }}>
                        <HelpOutlineIcon fontSize="inherit" />
                      </IconButton>
                    </Tooltip>
                  </label>
                  <select
                    className="form-select"
                    name="stockType"
                    value={formData.stockType || ""}
                    onChange={handleChange}
                  >
                    <option></option>
                    <option value="pallet">Pallet</option>
                    <option value="box">Box</option>
                    <option value="set">Set</option>
                    <option value="individual">Individual</option>
                  </select>
                </div>

                {formData.stockType && (
                  <div className="col-12">
                    <label className="form-label">
                      Quantity per Stock
                      <Tooltip
                        title={
                          <span style={{ fontSize: ".8rem" }}>
                            Total count of the item in each stock type (eg: There
                            are 100 Brackets in each box, therefore, the{" "}
                            <span className="text-warning">
                              Quantity Per Stock
                            </span>{" "}
                            will be 100)
                          </span>
                        }
                        arrow
                      >
                        <IconButton size="small" sx={{ color: "#fff" }}>
                          <HelpOutlineIcon fontSize="inherit" />
                        </IconButton>
                      </Tooltip>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Specify the quantity per stock item"
                      pattern="^\d*(\.\d{0,2})?$"
                      name="qtyPerStock"
                      value={formData.qtyPerStock || ""}
                      onChange={handleChange}
                    />
                  </div>
                )}

                {formData.qtyPerStock && (
                  <div className="col-12">
                    <label className="form-label">
                      Unit Type
                      <Tooltip
                        title={
                          <span style={{ fontSize: ".8rem" }}>
                            In which unit is the item measured/sold (eg: The
                            headrail is measured per foot, therefore, the{" "}
                            <span className="text-warning">Unit Type</span> will
                            be in feet)
                          </span>
                        }
                        arrow
                      >
                        <IconButton size="small" sx={{ color: "#fff" }}>
                          <HelpOutlineIcon fontSize="inherit" />
                        </IconButton>
                      </Tooltip>
                    </label>
                    <select
                      className="form-select"
                      name="unit"
                      value={formData.unit || ""}
                      onChange={handleChange}
                    >
                      <option></option>
                      <option value="sqft">SQFT</option>
                      <option value="foot">Foot</option>
                      <option value="units">Units (Individually)</option>
                    </select>
                  </div>
                )}

                {formData.unit && (
                  <div className="col-12">
                    <label className="form-label">
                      Quantity in One Unit
                      <Tooltip
                        title={
                          <span
                            style={{ fontSize: ".8rem" }}
                          >
                            Total quantity in a single unit (eg: The total length
                            of a single Headrail is 18 feet, therefore, the{" "}
                            <span className="text-warning">
                              Quantity in One Unit
                            </span>{" "}
                            will be 18 Foot)
                          </span>
                        }
                        arrow
                      >
                        <IconButton size="small" sx={{ color: "#fff" }}>
                          <HelpOutlineIcon fontSize="inherit" />
                        </IconButton>
                      </Tooltip>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Specify the number of units in 1 countable item"
                      pattern="^\d*(\.\d{0,2})?$"
                      name="unitFactor"
                      value={formData.unitFactor || ""}
                      onChange={handleChange}
                    />
                  </div>
                )}

                {formData.unitFactor && (
                  <>
                    <div className="col-12">
                      <label className="form-label">
                        In Stock
                        <Tooltip
                          title={
                            <span style={{ fontSize: ".8rem" }}>
                              Accurate entry here helps in tracking inventory levels
                              and planning for reorders. Ensure this number reflects
                              the actual{" "}
                              <span className="text-warning">number of units physically in stock</span>.
                            </span>
                          }
                          arrow
                        >
                          <IconButton size="small" sx={{ color: "#fff" }}>
                            <HelpOutlineIcon fontSize="inherit" />
                          </IconButton>
                        </Tooltip>
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="In Stock"
                        pattern="^\d*(\.\d{0,2})?$"
                        name="inStock"
                        value={formData.inStock || ""}
                        onChange={handleChange}
                      />
                    </div>

                    {formData.inStock && (
                      <div className="col-12">
                        <label className="form-label">
                          Stock Alert
                          <Tooltip
                            title={
                              <span style={{ fontSize: ".8rem" }}>
                                Set the <span className="text-warning">alert</span> level for stock to avoid running out.
                              </span>
                            }
                            arrow
                          >
                            <IconButton size="small" sx={{ color: "#fff" }}>
                              <HelpOutlineIcon fontSize="inherit" />
                            </IconButton>
                          </Tooltip>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Stock Alert"
                          pattern="^\d*(\.\d{0,2})?$"
                          name="stockAlert"
                          value={formData.stockAlert || ""}
                          onChange={handleChange}
                        />
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StockDetailsForm;
