import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/layout/Header';
import Sidebar from '../../components/layout/Sidebar';
import { fetchItems } from '../../redux/actions/InventoryAction';
import CategoryTable from '../../components/inventoryManagement/CategoryTable';
import Loader from '../../components/layout/Loader';

const ListInventory = ({ nav }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { userInfo } = useSelector(state => state.userSignIn);
    const { data, loading, error } = useSelector(state => state.inventorytabledata);

    useEffect(() => {
        if (!userInfo) {
            navigate('/sign-in');
        } else {
            dispatch(fetchItems(userInfo.token));
        }
        window.scrollTo(0, 0);
    }, [userInfo, navigate, dispatch]);

    if (error) return <div className="text-center text-danger">{error}</div>;

    console.log(data);

    return (
        <>
            {userInfo && (
                <div className={nav.isSidebar}>
                    <Header nav={nav} />
                    <Sidebar nav={nav} />
                    {/*start content*/}
                    <main className="page-content">
                        <h5 className='pt-3'>
                            <i className="fadeIn animated bx bx-file" />
                            &ensp;Inventory List
                        </h5>
                        <br />
                        {loading ? (
                            <div className="text-center">
                                <Loader />
                            </div>
                        ) : error ? (
                            <div className="text-center text-danger">{error}</div>
                        ) : (
                            data.map((category) => (
                                <CategoryTable key={category.category} item={category} />
                            ))
                        )}
                    </main>
                </div>
            )}
        </>
    );
};

export default ListInventory;
