import React from "react";
import ProDependency from "../ProDependency";

const DependancyForm = ({ formData, setFormData  }) => {
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12 col-lg-8 px-4">
          <div
            className="card shadow-none bg-light border"
            style={{ padding: "20px", maxWidth: "100%" }}
          >
            <div className="card-body">
              <h5 className="card-title mb-4 text-primary">Dependencies</h5>
              <div className="row g-3">
                <div className="col-12">
                  <ProDependency
                    proDep={formData.proDep}
                    setProDep={(proDep) =>
                      setFormData((prevState) => ({
                        ...prevState,
                        proDep,
                      }))
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DependancyForm;
