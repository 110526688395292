import React from 'react'
import { Link } from 'react-router-dom'
import Header from '../../components/layout/Header'
import Sidebar from '../../components/layout/Sidebar'

const ViewInventoryItem = ({ nav }) => {
    return (
        <>
            <div className={nav.isSidebar}>
                <Header nav={nav} />
                <Sidebar nav={nav} />
                {/*start content*/}
                <main className="page-content">
                    <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                        <div className="breadcrumb-title pe-3">Inventory Management</div>
                        <div className="ps-3">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/">
                                            <i className="bx bx-home-alt" />
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        View Inventory Item
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>

                    <div className="row">
                        <form
                        // onSubmit={handleSubmit}
                        >
                            <div className="col-lg-12 mx-auto">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row g-3">
                                            <div className="col-12 col-lg-4">
                                                <div className="card shadow-none bg-light border">
                                                    <div className="card-body">
                                                        <h5 class="card-title mb-4 text-success">Item Detail</h5>
                                                        <div className="row g-3">
                                                            <div className="col-12">
                                                                <label className="form-label"> Name</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Item Name"
                                                                // value={name} onChange={(e) => setName(e.target.value)}
                                                                />
                                                            </div>
                                                            <div className="col-12">
                                                                <label className="form-label">Item Category</label>
                                                                <select className="form-select"
                                                                // value={category} onChange={(e) => setCategory(e.target.value)}
                                                                >
                                                                    <option></option>
                                                                    <option value='fabric'>Fabric</option>
                                                                    <option value='components'>Components</option>
                                                                    <option value='hardware'>Hardware</option>
                                                                </select>
                                                            </div>
                                                            <div className="col-12">
                                                                <label className="form-label">Item Remarks/Description</label>
                                                                <textarea
                                                                    className="form-control"
                                                                    placeholder="Specify the item and how it's been used"
                                                                    rows={6}
                                                                    cols={4}
                                                                    defaultValue={""}
                                                                // value={des} onChange={(e) => setDes(e.target.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-4">
                                                <div className="card shadow-none bg-light border">
                                                    <div className="card-body">
                                                        <h5 class="card-title mb-4 text-warning">Stock Detail</h5>
                                                        <div className="row g-3">
                                                            <div className="col-12">
                                                                <label className="form-label">In Stock</label>
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    placeholder="In Stock"
                                                                    pattern="^\d*(\.\d{0,2})?$"
                                                                // value={inStock} onChange={(e) => setInStock(e.target.value)}
                                                                />
                                                            </div>
                                                            <div className="col-12">
                                                                <label className="form-label">Stock Alert</label>
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    placeholder="Stock Alert"
                                                                    pattern="^\d*(\.\d{0,2})?$"
                                                                // value={stockAlert} onChange={(e) => setStockAlert(e.target.value)}
                                                                />
                                                            </div>
                                                            <div className="col-12">
                                                                <label className="form-label">Unit</label>
                                                                <select className="form-select"
                                                                // value={unit} onChange={(e) => setUnit(e.target.value)}
                                                                >
                                                                    <option></option>
                                                                    <option value='sqft'>SQFT</option>
                                                                    <option value='foot'>Foot</option>
                                                                    <option value='meter'>Meter</option>
                                                                    <option value='units'>Units (Individually)</option>
                                                                </select>
                                                            </div>
                                                            <div className="col-12">
                                                                <label className="form-label">Unit Factor</label>
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    placeholder="Specify the number of units in 1 countable item"
                                                                    pattern="^\d*(\.\d{0,2})?$"
                                                                // value={unitFactor} onChange={(e) => setUnitFactor(e.target.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                        {/*end row*/}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-4">
                                                <div className="card shadow-none bg-light border">
                                                    <div className="card-body">
                                                        <h5 class="card-title mb-4 text-info">Pricing & Costing</h5>
                                                        <div className="row g-3">
                                                            <div className="col-12">
                                                                <label className="form-label">Cost per Unit</label>
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    placeholder="Cost in Dollars"
                                                                    pattern="^\d*(\.\d{0,2})?$"
                                                                // value={cost} onChange={(e) => setCost(e.target.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                        {/*end row*/}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*end row*/}
                                    </div>
                                    <div className="card-header py-3 bg-transparent">
                                        <div className="d-sm-flex align-items-center">
                                            <h5 className="mb-2 mb-sm-0"> Add New Inventory Item</h5>
                                            <div className="ms-auto">
                                                <button type="submit"
                                                    className="btn btn-primary"
                                                // onSubmit={handleSubmit}
                                                >
                                                    Create New Item
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                </main >
            </div>
        </>
    )
}

export default ViewInventoryItem