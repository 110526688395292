//This is the Header of the Order Table
export const OrderTableHeader = ({ productType, isEdit }) => {
    return (
        <>
            <thead>
                <tr>
                    <th>#</th>
                    <th>Width</th>
                    <th>Height</th>
                    {
                        (() => {
                            switch (productType) {
                                case 'Zebra_blinds':
                                case 'Roller_shades':
                                    return (
                                        <>
                                            <th>Fascia</th>
                                            <th>Tube</th>
                                            <th>Fabric</th>
                                        </>
                                    )
                                case 'Fauxwood':
                                    return (
                                        <>
                                            <th>R-Width</th>
                                            <th>R-Height</th>
                                            <th>Slat</th>
                                        </>
                                    )
                                case 'Vertical_blinds':
                                    return (
                                        <>
                                            <th>Chain</th>
                                            <th>Cord</th>
                                            <th>Track</th>
                                            <th>Vanes</th>
                                            <th>MD Vanes</th>
                                            <th>PVC</th>
                                        </>
                                    )
                                default:
                                    return null
                            }
                        })()
                    }
                    <th>Remarks</th>
                    <th>Location</th>
                    {
                        isEdit && (
                            <th>Actdddion</th>
                        )
                    }
                </tr>
            </thead>
        </>
    )
}

export const OrderTableValues = ({ o, productType, isEdit }) => {
    return (
        <>
            {!isEdit && (
                <>
                    <td className="h5" >{o.width}</td>
                    <td className="h5">{o.height}</td>
                </>
            )}
            {
                (() => {
                    switch (productType) {
                        case 'Zebra_blinds':
                        case 'Roller_shades':
                            return (
                                <>
                                    <td className="h5">{o.fascia}</td>
                                    <td className="h5">{o.tube}</td>
                                    <td className="h5">{o.fabric}</td>
                                </>
                            )
                        case 'Fauxwood':
                            return (
                                <>
                                    <td className="h5">{o.readyWidth}</td>
                                    <td className="h5">{o.height}</td>
                                    <td className="h5">{o.slat}</td>
                                </>
                            )
                        case 'Vertical_blinds':
                            return (
                                <>
                                    <td className="h5">{o.chain}</td>
                                    <td className="h5">{o.cord}</td>
                                    <td className="h5">{o.track}</td>
                                    <td className="h5">{o.vanes}</td>
                                    <td className="h5">{o.vaneDed}</td>
                                    <td className="h5">{o.pvc}</td>
                                </>
                            )
                        default:
                            return null

                    }
                })()
            }


            <td >{o.remark}</td>
            <td >{o.location}</td>
        </>
    )
}

